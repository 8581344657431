import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectClientRegistration,
    selectHasErrors as selectLocationHasErrors,
    selectIsLoading as selectLocationIsLoading,
    selectPinnedLocation,
    selectStores as selectLocationStores,
    selectRetailCentres,
    selectTargetStoreCategory
} from "modules/customer/tools/location/locationSlice";
import { ScoreField } from "modules/customer/tools/location/retailCentre";
import { apiPost, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { openStreetView as mapOpenStreetView } from "modules/helpers/maps/mapsSlice";
import { RootState } from "store";
import { numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

import { DefaultStoreFeatures, loadDefaultStoreFeatures } from "./defaultStoreFeatures";
import { loadModelFeatures, ModelFeature, RelevantModelFeature, RelevantModelFeatureType } from "./modelFeature";
import { loadPredictionFeatures, PredictionFeature } from "./predictionFeature";
import { loadWeeklyRevenueShare, WeeklyRevenueShare } from "./weeklyRevenueShare";
import { AnalogueStoreGroup } from "./analogueStoreGroup";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { distance, sqrt } from "mathjs";
import { AnalogueStore } from "./analogueStore";
import { AlignmentCentile, AnalogueAlignmentMetric, analogueAlignmentMetrics } from "./analogueAlignmentMetrics";

interface LoadOverviewResponse {
    defaultStoreFeatures: DefaultStoreFeatures,
    weeklyRevenueShare: WeeklyRevenueShare,
    predictionFeatures: PredictionFeature[],
    modelFeatures: ModelFeature[],
    analogueStoreGroups: AnalogueStoreGroup[]
}

interface RevenuePrediction {
    baselineValue: number,
    predictionValue: number,
    features: RevenuePredictionFeature[],
    deflections: Deflection[],
    predictedCatchments: PredictedCatchments[]
}

interface RevenuePredictionFeature {
    id: number,
    featureValueId: number,
    shapValue: number,
    averageValue: number,
    actualValue: number
}

interface Deflection {
    storeId: string,
    storeName: string,
    percentageChange: number,
    revisedRevenue: number
}

interface PredictedCatchments {
    outputAreaCode: string,
    probability: number
}

interface RevenuePredictionDriver {
    name: string,
    shapValue: number,
    description: string,
    averageValue: number,
    actualValue: number,
    positivePrefix: string,
    negativePrefix: string,
    suffix: string
}

interface GenerateRevenuePredictionVisibility {
    isVisible: boolean
}

export enum GenerateRevenuePredictionStep {
    SetupPrediction,
    InputStoreParameters
}

interface PredictionForm {
    group: string,
    model: string,
    salesAreaInSqft: string,
    closingStoresIds: string[]
}

interface OverrideRevenuePredictionConfirmationVisibility {
    isVisible: boolean
}

interface OverviewState {
    isLoading: boolean,
    hasErrors: boolean,
    defaultStoreFeatures?: DefaultStoreFeatures,
    modelFeatures: ModelFeature[],
    revenuePrediction?: RevenuePrediction,
    weeklyRevenueShare: WeeklyRevenueShare,
    generateRevenuePredictionVisibility: GenerateRevenuePredictionVisibility,
    activeGenerateRevenuePredictionStep: GenerateRevenuePredictionStep,
    predictionForm: PredictionForm,
    candidatePredictionForm: PredictionForm,
    storeParametersForm: Map<string, string>,
    candidateStoreParametersForm: Map<string, string>,
    hasPrediction: boolean,
    predictionIsLoading: boolean,
    predictionHasErrors: boolean,
    overrideRevenuePredictionConfirmationVisibility: OverrideRevenuePredictionConfirmationVisibility,
    predictionFeatures: PredictionFeature[],
    analogueStoreGroups: AnalogueStoreGroup[],
    analogueAlignmentMetrics: AnalogueAlignmentMetric[]
}

const initialState: OverviewState = {
    isLoading: false,
    hasErrors: false,
    defaultStoreFeatures: undefined,
    modelFeatures: [],
    revenuePrediction: undefined,
    weeklyRevenueShare: new WeeklyRevenueShare(0, 0),
    generateRevenuePredictionVisibility: {
        isVisible: false
    },
    activeGenerateRevenuePredictionStep: GenerateRevenuePredictionStep.SetupPrediction,
    predictionForm: {
        group: "",
        model: "",
        salesAreaInSqft: "1",
        closingStoresIds: []
    },
    candidatePredictionForm: {
        group: "",
        model: "",
        salesAreaInSqft: "1",
        closingStoresIds: []
    },
    storeParametersForm: new Map<string, string>(),
    candidateStoreParametersForm: new Map<string, string>(),
    hasPrediction: false,
    predictionIsLoading: false,
    predictionHasErrors: false,
    overrideRevenuePredictionConfirmationVisibility: {
        isVisible: false
    },
    predictionFeatures: [],
    analogueStoreGroups: [],
    analogueAlignmentMetrics: analogueAlignmentMetrics
};

const overviewSlice = createSlice({
    name: "customer/tools/location/overview",
    initialState,
    reducers: {
        setDefaultStoreFeatures: (state, action: PayloadAction<DefaultStoreFeatures>) => {
            state.defaultStoreFeatures = action.payload;
        },
        clearDefaultStoreFeatures: (state) => {
            state.defaultStoreFeatures = initialState.defaultStoreFeatures;
        },
        clearModelFeatures: (state) => {
            state.modelFeatures = initialState.modelFeatures;
        },
        clearRevenuePrediction: (state) => {
            state.revenuePrediction = initialState.revenuePrediction;
        },
        clearWeeklyRevenueShare: (state) => {
            state.weeklyRevenueShare = initialState.weeklyRevenueShare;
        },
        clearPredictionFeatures: (state) => {
            state.predictionFeatures = initialState.predictionFeatures;
        },
        showGenerateRevenuePrediction: (state) => {
            state.activeGenerateRevenuePredictionStep = GenerateRevenuePredictionStep.SetupPrediction;
            state.generateRevenuePredictionVisibility.isVisible = true;
        },
        hideGenerateRevenuePrediction: (state) => {
            state.generateRevenuePredictionVisibility.isVisible = false;
        },
        setActiveGenerateRevenuePredictionStep: (state, action: PayloadAction<GenerateRevenuePredictionStep>) => {
            state.activeGenerateRevenuePredictionStep = action.payload;
        },
        clearActiveGenerateRevenuePredictionStep: (state) => {
            state.activeGenerateRevenuePredictionStep = initialState.activeGenerateRevenuePredictionStep;
        },
        setCandidatePredictionForm: (state, action: PayloadAction<PredictionForm>) => {
            state.candidatePredictionForm = action.payload;
        },
        clearCandidatePredictionForm: (state) => {
            state.candidatePredictionForm = initialState.candidatePredictionForm;
        },
        setCandidateStoreParametersForm: (state, action: PayloadAction<Map<string, string>>) => {
            const map = action.payload;
            const newMap = new Map<string, string>();
            if (map.size > 0) {
                map.forEach((value, key) => newMap.set(key, value));
            }
            state.candidateStoreParametersForm = newMap;
        },
        updateCandidateStoreParametersForm: (state, action: PayloadAction<{ key: string, value: string }>) => {
            state.candidateStoreParametersForm.set(action.payload.key, action.payload.value);
        },
        clearCandidateStoreParametersForm: (state) => {
            state.candidateStoreParametersForm = initialState.candidateStoreParametersForm;
        },
        choosePredictionFormAndStoreParametersForm: (state, action: PayloadAction<{
            predictionForm: PredictionForm,
            storeParametersForm: Map<string, string>
        }>) => {
            state.predictionForm = action.payload.predictionForm;
            const map = action.payload.storeParametersForm;
            const newMap = new Map<string, string>();
            if (map.size > 0) {
                map.forEach((value, key) => newMap.set(key, value));
            }
            state.storeParametersForm = newMap;
        },
        clearPredictionFormAndStoreParametersForm: (state) => {
            state.predictionForm = initialState.predictionForm;
            state.storeParametersForm = initialState.storeParametersForm;
        },
        clearHasPrediction: (state) => {
            state.hasPrediction = initialState.hasPrediction;
        },
        showOverrideRevenuePredictionConfirmation: (state) => {
            state.overrideRevenuePredictionConfirmationVisibility.isVisible = true;
        },
        hideOverrideRevenuePredictionConfirmation: (state) => {
            state.overrideRevenuePredictionConfirmationVisibility.isVisible = false;
        },
        clearAnalogueStoreGroups: (state) => {
            state.analogueStoreGroups = initialState.analogueStoreGroups;
        },
        toggleAnalogueStoreGroupIsSelected: (state, action: PayloadAction<string>) => {
            const groupName = action.payload;
            state.analogueStoreGroups.find(storeGroup => storeGroup.name === groupName)?.toggleIsSelected();
        },
        chooseAllAnalogueStoreGroups: (state) => {
            state.analogueStoreGroups.forEach(storeGroup => storeGroup.setIsSelected(true));
        },
        deselectAllAnalogueStoreGroups: (state) => {
            state.analogueStoreGroups.forEach(storeGroup => storeGroup.setIsSelected(false));
        },
        resetAnalogueAlignmentMetrics: (state) => {
            state.analogueAlignmentMetrics = initialState.analogueAlignmentMetrics;
        },
        toggleAnalogueAlignmentMetricIsSelected: (state, action: PayloadAction<string>) => {
            const metricName = action.payload;
            state.analogueAlignmentMetrics.find(metric => metric.name === metricName)?.toggleIsSelected();
        },
        chooseAllAnalogueAlignmentMetrics: (state) => {
            state.analogueAlignmentMetrics.forEach(metric => metric.setIsSelected(true));
        },
        deselectAllAnalogueAlignmentMetrics: (state) => {
            state.analogueAlignmentMetrics.forEach(metric => metric.setIsSelected(false));
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadOverview.pending, (state: OverviewState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadOverview.rejected, (state: OverviewState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.defaultStoreFeatures = initialState.defaultStoreFeatures;
            state.revenuePrediction = initialState.revenuePrediction;
            state.weeklyRevenueShare = initialState.weeklyRevenueShare;
            state.predictionFeatures = initialState.predictionFeatures;
            state.generateRevenuePredictionVisibility = initialState.generateRevenuePredictionVisibility;
            state.overrideRevenuePredictionConfirmationVisibility = initialState.overrideRevenuePredictionConfirmationVisibility;
            state.modelFeatures = initialState.modelFeatures;
            state.analogueStoreGroups = initialState.analogueStoreGroups;
        });
        builder.addCase(loadOverview.fulfilled, (state: OverviewState, action: PayloadAction<LoadOverviewResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.defaultStoreFeatures = action.payload.defaultStoreFeatures;
            state.weeklyRevenueShare = action.payload.weeklyRevenueShare;
            state.predictionFeatures = action.payload.predictionFeatures;
            state.modelFeatures = action.payload.modelFeatures;
            state.analogueStoreGroups = action.payload.analogueStoreGroups;
        });
        builder.addCase(generateRevenuePrediction.pending, (state: OverviewState) => {
            state.hasPrediction = true;
            state.predictionIsLoading = true;
            state.predictionHasErrors = false;
        });
        builder.addCase(generateRevenuePrediction.rejected, (state: OverviewState) => {
            state.hasPrediction = true;
            state.predictionIsLoading = false;
            state.predictionHasErrors = true;
        });
        builder.addCase(generateRevenuePrediction.fulfilled, (state: OverviewState, action: PayloadAction<RevenuePrediction>) => {
            state.hasPrediction = true;
            state.predictionIsLoading = false;
            state.predictionHasErrors = false;
            state.revenuePrediction = action.payload;
        });
    }
});

export const {
    showGenerateRevenuePrediction,
    hideGenerateRevenuePrediction,
    setCandidatePredictionForm,
    setCandidateStoreParametersForm,
    updateCandidateStoreParametersForm,
    clearCandidateStoreParametersForm,
    showOverrideRevenuePredictionConfirmation,
    hideOverrideRevenuePredictionConfirmation,
    setActiveGenerateRevenuePredictionStep,
    toggleAnalogueStoreGroupIsSelected,
    chooseAllAnalogueStoreGroups,
    deselectAllAnalogueStoreGroups,
    toggleAnalogueAlignmentMetricIsSelected,
    chooseAllAnalogueAlignmentMetrics,
    deselectAllAnalogueAlignmentMetrics
} = overviewSlice.actions;

export const loadOverview = createAppAsyncThunk(
    "customer/tools/location/overview/loadOverview",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const stores = selectStores(state);
            const allStoreGroups = stores
                .map(store => store.group);
            const groupNames = Array.from(new Set(allStoreGroups));
            const analogueStoreGroups = groupNames.map(groupName => new AnalogueStoreGroup(groupName, true))
                .sort((groupA, groupB) => stringSortExpression(groupA.name, groupB.name, SortDirection.ASC));

            const defaultStoreFeaturesPromise = thunkAPI.dispatch(loadDefaultStoreFeatures());
            const weeklyRevenueSharePromise = thunkAPI.dispatch(loadWeeklyRevenueShare());
            const predictionFeaturesPromise = thunkAPI.dispatch(loadPredictionFeatures());
            const modelFeaturesPromise = thunkAPI.dispatch(loadModelFeatures());
            const result = await Promise.all([
                defaultStoreFeaturesPromise,
                weeklyRevenueSharePromise,
                predictionFeaturesPromise,
                modelFeaturesPromise
            ]);
            const defaultStoreFeatures = result[0];
            const weeklyRevenueShare = result[1];
            const predictionFeatures = result[2];
            const modelFeatures = result[3];
            const loadOverviewResponse: LoadOverviewResponse = {
                defaultStoreFeatures,
                weeklyRevenueShare,
                predictionFeatures,
                modelFeatures,
                analogueStoreGroups
            };
            return loadOverviewResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Overview.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearOverview = (): AppThunk => (dispatch) => {
    dispatch(overviewSlice.actions.clearDefaultStoreFeatures());
    dispatch(overviewSlice.actions.clearModelFeatures());
    dispatch(overviewSlice.actions.clearRevenuePrediction());
    dispatch(overviewSlice.actions.clearWeeklyRevenueShare());
    dispatch(overviewSlice.actions.clearPredictionFeatures());
    dispatch(overviewSlice.actions.hideGenerateRevenuePrediction());
    dispatch(overviewSlice.actions.clearActiveGenerateRevenuePredictionStep());
    dispatch(overviewSlice.actions.clearCandidatePredictionForm());
    dispatch(overviewSlice.actions.clearCandidateStoreParametersForm());
    dispatch(overviewSlice.actions.clearPredictionFormAndStoreParametersForm());
    dispatch(overviewSlice.actions.clearHasPrediction());
    dispatch(overviewSlice.actions.resetAnalogueAlignmentMetrics());
    dispatch(overviewSlice.actions.hideOverrideRevenuePredictionConfirmation());
    dispatch(overviewSlice.actions.clearAnalogueStoreGroups());
};

export const generateRevenuePrediction = createAppAsyncThunk(
    "customer/tools/location/overview/generateRevenuePrediction",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const candidatePredictionForm = selectCandidatePredictionForm(state);
            const candidateStoreParametersForm = selectCandidateStoreParametersForm(state);
            thunkAPI.dispatch(overviewSlice.actions.choosePredictionFormAndStoreParametersForm({
                predictionForm: candidatePredictionForm,
                storeParametersForm: candidateStoreParametersForm
            }));
            const pinnedLocation = selectPinnedLocation(state);
            const primaryStoreCategoryName = selectClientRegistration(state)?.primaryStoreCategoryName ?? "";
            const response = await thunkAPI.dispatch(apiPost("/customer/tools/location/revenue-prediction", {
                latitude: pinnedLocation?.latitude ?? 0,
                longitude: pinnedLocation?.longitude ?? 0,
                region: pinnedLocation?.retailCentre?.regionName ?? "",
                retailCentreCode: pinnedLocation?.retailCentre?.code ?? "",
                storeCategory: primaryStoreCategoryName,
                group: candidatePredictionForm.group,
                model: candidatePredictionForm.model,
                salesAreaInSqft: candidatePredictionForm.salesAreaInSqft ? Number(candidatePredictionForm.salesAreaInSqft) : 0,
                closingStoresIds: candidatePredictionForm.closingStoresIds,
                storeParameters: Object.fromEntries(candidateStoreParametersForm)
            }, false));
            if (response.status === ApiResponseStatus.Ok) {
                // Response of Generate Revenue API
                return response.data.revenuePrediction;
            }
            return thunkAPI.rejectWithValue(null);
        } catch (error) {
            thunkAPI.dispatch(logError("Error generating RevenuePrediction.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const openStreetView = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const pinnedLocation = selectPinnedLocation(state);
    const latitude = pinnedLocation?.latitude ?? 0;
    const longitude = pinnedLocation?.longitude ?? 0;
    dispatch(mapOpenStreetView(latitude, longitude));
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.location.overview.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.location.overview.hasErrors;
};

export const selectDefaultStoreFeatures = (state: RootState) => {
    return state.customer.tools.location.overview.defaultStoreFeatures;
};

export const selectModelFeatures = (state: RootState) => {
    return state.customer.tools.location.overview.modelFeatures;
};

export const selectWeeklyRevenueShare = (state: RootState) => {
    return state.customer.tools.location.overview.weeklyRevenueShare;
};

export const selectPredictionFeatures = (state: RootState) => {
    return state.customer.tools.location.overview.predictionFeatures;
};

export const selectRevenuePrediction = (state: RootState) => {
    return state.customer.tools.location.overview.revenuePrediction;
};

export const selectGenerateRevenuePredictionVisibility = (state: RootState) => {
    return state.customer.tools.location.overview.generateRevenuePredictionVisibility;
};

export const selectActiveGenerateRevenuePredictionStep = (state: RootState) => {
    return state.customer.tools.location.overview.activeGenerateRevenuePredictionStep;
};

export const selectPredictionForm = (state: RootState) => {
    return state.customer.tools.location.overview.predictionForm;
};

export const selectCandidatePredictionForm = (state: RootState) => {
    return state.customer.tools.location.overview.candidatePredictionForm;
};

export const selectStoreParametersForm = (state: RootState) => {
    return state.customer.tools.location.overview.storeParametersForm;
};

export const selectCandidateStoreParametersForm = (state: RootState) => {
    return state.customer.tools.location.overview.candidateStoreParametersForm;
};

export const selectHasPrediction = (state: RootState) => {
    return state.customer.tools.location.overview.hasPrediction;
};

export const selectPredictionIsLoading = (state: RootState) => {
    return state.customer.tools.location.overview.predictionIsLoading;
};

export const selectPredictionHasErrors = (state: RootState) => {
    return state.customer.tools.location.overview.predictionHasErrors;
};

export const selectOverrideRevenuePredictionConfirmationVisibility = (state: RootState) => {
    return state.customer.tools.location.overview.overrideRevenuePredictionConfirmationVisibility;
};

export const selectAnalogueStoreGroups = (state: RootState) => {
    return state.customer.tools.location.overview.analogueStoreGroups;
};

export const selectAnalogueAlignmentMetrics = (state: RootState) => {
    return state.customer.tools.location.overview.analogueAlignmentMetrics;
};

export const selectLocationAlignment = createSelector(
    (state: RootState) => selectLocationIsLoading(state),
    (state: RootState) => selectLocationHasErrors(state),
    (state: RootState) => selectPinnedLocation(state),
    (isLoading, hasErrors, pinnedLocation) => {
        const id = "location-alignment";
        let label = "Location alignment";
        let status = RagIndicatorStatus.Info;
        if (isLoading || hasErrors) {
            return new RagIndicator(id, status, label, "", isLoading, hasErrors);
        }
        if (!pinnedLocation) {
            return new RagIndicator(id, status, label, "No location selected.");
        }
        const score = pinnedLocation.retailCentre.getRagScore(ScoreField.Overall);
        if (score >= 4) {
            status = RagIndicatorStatus.Green;
            label = "The selected location aligns strongly with your targets.";
        } else if (score >= 2) {
            status = RagIndicatorStatus.Amber;
            label = "The selected location aligns averagely with your targets.";
        } else {
            status = RagIndicatorStatus.Red;
            label = "The selected location aligns weakly with your targets.";
        }
        return new RagIndicator(id, status, label, "");
    }
);

export const selectStores = createSelector(
    (state: RootState) => selectLocationIsLoading(state),
    (state: RootState) => selectLocationHasErrors(state),
    (state: RootState) => selectLocationStores(state),
    (isLoading, hasErrors, stores) => {
        if (isLoading || hasErrors || !stores) {
            return [];
        }
        return stores
            .filter(store => store.revenue > 0)
            .sort((storeA, storeB) => stringSortExpression(storeA.name, storeB.name, SortDirection.ASC));
    }
);

export const selectNumberOfOpenStoresWithSalesInTheLastTwelveMonths = createSelector(
    selectStores,
    (stores) => {
        return stores.length;
    }
);

export const selectGroups = createSelector(
    selectStores,
    (stores) => {
        const groups = stores
            .map(store => store.group)
            .sort((groupA, groupB) => stringSortExpression(groupA, groupB, SortDirection.ASC));
        return Array.from(new Set(groups));
    }
);

export const selectModels = createSelector(
    selectStores,
    (stores) => {
        return _(stores)
            .groupBy(store => store.group)
            .map((collection, key) => ({
                value: key,
                count: _.size(collection)
            }))
            .value()
            .filter(group => group.count >= 25)
            .sort((groupA, groupB) => stringSortExpression(groupA.value, groupB.value, SortDirection.ASC))
            .map(group => group.value);
    }
);

export const selectRelevantModelFeatures = createSelector(
    selectModelFeatures,
    selectCandidatePredictionForm,
    (modelFeatures, candidatePredictionForm) => {
        return _(modelFeatures)
            .filter(modelFeature => modelFeature.model === candidatePredictionForm.model)
            .groupBy(modelFeature => modelFeature.featureId)
            .map((group) => {
                const id = group[0].featureId;
                const name = group[0].defaultName;
                const displayName = group[0].displayName;
                const values = group.map(modelFeature => modelFeature.featureValue).filter(value => value);
                const type = values.length > 0 ? RelevantModelFeatureType.Categorical : RelevantModelFeatureType.Numerical;
                return new RelevantModelFeature(id, type, name, displayName, values);
            })
            .value();
    }
);

export const selectTypicalYearRevenuePrediction = createSelector(
    selectRevenuePrediction,
    (revenuePrediction) => {
        return revenuePrediction?.predictionValue ?? 0;
    }
);

export const selectTypicalWeekRevenuePrediction = createSelector(
    selectTypicalYearRevenuePrediction,
    selectWeeklyRevenueShare,
    (typicalYearRevenuePrediction, weeklyRevenueShare) => {
        return typicalYearRevenuePrediction * weeklyRevenueShare.medianWeek;
    }
);

export const selectPeakWeekRevenuePrediction = createSelector(
    selectTypicalYearRevenuePrediction,
    selectWeeklyRevenueShare,
    (typicalYearRevenuePrediction, weeklyRevenueShare) => {
        return typicalYearRevenuePrediction * weeklyRevenueShare.peakWeek;
    }
);

export const selectPredictedCatchments = createSelector(
    selectRevenuePrediction,
    (revenuePrediction) => {
        return revenuePrediction?.predictedCatchments;
    }
);

export const selectRevenuePredictionDrivers = createSelector(
    selectRevenuePrediction,
    selectPredictionFeatures,
    selectModelFeatures,
    selectPredictionForm,
    (revenuePrediction, predictionFeatures, modelFeatures, predictionForm) => {
        if (!revenuePrediction) {
            return [];
        }

        const relevantModelFeatures = modelFeatures.filter(modelFeature => modelFeature.model === predictionForm.model);

        const revenuePredictionDrivers: RevenuePredictionDriver[] = [];

        const baselinePredictionFeature = predictionFeatures.find(predictionFeature => predictionFeature.name.toLowerCase() === "baseline");
        revenuePredictionDrivers.push({
            name: "Baseline",
            shapValue: revenuePrediction.baselineValue,
            description: baselinePredictionFeature?.description ?? "",
            averageValue: 0,
            actualValue: 0,
            positivePrefix: baselinePredictionFeature?.positivePrefix ?? "",
            negativePrefix: baselinePredictionFeature?.negativePrefix ?? "",
            suffix: baselinePredictionFeature?.suffix ?? ""
        });

        const sortedRevenuePredictionFeatures = [...revenuePrediction.features]
            .sort((featureA, featureB) => numberSortExpression(Math.abs(featureA.shapValue), Math.abs(featureB.shapValue), SortDirection.DESC));

        const numberOfSingleFeaturesOnDisplay = 7;

        for (let i = 0; i < numberOfSingleFeaturesOnDisplay; i++) {
            const revenuePredictionFeature = sortedRevenuePredictionFeatures[i];
            if (revenuePredictionFeature) {
                const predictionFeature = predictionFeatures.find(predictionFeature => predictionFeature.id === revenuePredictionFeature.id);
                const modelFeature = relevantModelFeatures.find(modelFeature => modelFeature.featureId === revenuePredictionFeature.id && modelFeature.featureValueId === revenuePredictionFeature.featureValueId);
                const name = modelFeature?.displayName ?? predictionFeature?.name ?? "";
                const positivePrefix = modelFeature
                    ? `${predictionFeature?.positivePrefix ?? ""} ${modelFeature?.featureValue ?? ""}`
                    : predictionFeature?.positivePrefix ?? "";
                const negativePrefix = modelFeature
                    ? `${predictionFeature?.negativePrefix ?? ""} ${modelFeature?.featureValue ?? ""}`
                    : predictionFeature?.negativePrefix ?? "";
                revenuePredictionDrivers.push({
                    name: name,
                    shapValue: revenuePredictionFeature.shapValue,
                    description: predictionFeature?.description ?? "",
                    averageValue: revenuePredictionFeature.averageValue,
                    actualValue: revenuePredictionFeature.actualValue,
                    positivePrefix: positivePrefix,
                    negativePrefix: negativePrefix,
                    suffix: predictionFeature?.suffix ?? ""
                });
            }
        }

        const allOtherRevenuePredictionFeatures = sortedRevenuePredictionFeatures.slice(numberOfSingleFeaturesOnDisplay);
        if (allOtherRevenuePredictionFeatures.length > 0) {
            const groupedRevenuePredictionFeatures = allOtherRevenuePredictionFeatures.reduce((accumulator, feature) => {
                accumulator.shapValue += feature.shapValue;
                accumulator.averageValue += feature.averageValue;
                accumulator.actualValue += feature.actualValue;
                return accumulator;
            }, {
                shapValue: 0,
                averageValue: 0,
                actualValue: 0
            });
            revenuePredictionDrivers.push({
                name: `${allOtherRevenuePredictionFeatures.length} other features`,
                shapValue: groupedRevenuePredictionFeatures.shapValue,
                description: "",
                averageValue: groupedRevenuePredictionFeatures.averageValue,
                actualValue: groupedRevenuePredictionFeatures.actualValue,
                positivePrefix: "",
                negativePrefix: "",
                suffix: ""
            });
        }

        const pinnedLocationPredictionFeature = predictionFeatures.find(predictionFeature => predictionFeature.name.toLowerCase() === "pinned location");
        revenuePredictionDrivers.push({
            name: "Pinned location",
            shapValue: revenuePrediction.predictionValue,
            description: pinnedLocationPredictionFeature?.description ?? "",
            averageValue: 0,
            actualValue: 0,
            positivePrefix: pinnedLocationPredictionFeature?.positivePrefix ?? "",
            negativePrefix: pinnedLocationPredictionFeature?.negativePrefix ?? "",
            suffix: pinnedLocationPredictionFeature?.suffix ?? ""
        });

        return revenuePredictionDrivers;
    }
);

export const selectAnalogueStores = createSelector(
    (state: RootState) => selectPinnedLocation(state),
    (state: RootState) => selectStores(state),
    (state: RootState) => selectRetailCentres(state),
    (state: RootState) => selectTargetStoreCategory(state),
    (state: RootState) => selectFeatureFlags(state),
    selectAnalogueAlignmentMetrics,
    (pinnedLocation, stores, retailCentres, targetStoreCategory, featureFlags, alignmentMetrics) => {
        const numberOfSelectedAlignmentMetrics = alignmentMetrics.filter(metric => metric.isSelected()).length;
        if (!pinnedLocation || !targetStoreCategory || (numberOfSelectedAlignmentMetrics === 0)) {
            return [];
        }

        const pinnedLocationCentiles = alignmentMetrics
            .filter(metric => metric.centile !== AlignmentCentile.Spend)
            .filter(metric => metric.isSelected())
            .map(metric => pinnedLocation.retailCentre[metric.centile]);

        const includeSpend = featureFlags.enableCustomerToolsLocationSpendNew;

        const spendAlignmentMetric = alignmentMetrics.find(metric => metric.centile === AlignmentCentile.Spend);
        if (includeSpend && spendAlignmentMetric?.isSelected()) {
            pinnedLocationCentiles.push(pinnedLocation.retailCentre.spendCentile);
        }

        const analogueStores: AnalogueStore[] = [];

        stores.forEach(store => {
            const scenarioCatchment = store.categoryId !== targetStoreCategory.id;
            const storeRetailCentre = scenarioCatchment
                ? retailCentres.find(retailCentre => retailCentre.id === store.retailCentre.id)
                : store.retailCentre;

            if (storeRetailCentre) {
                const storeCentiles = alignmentMetrics
                    .filter(metric => metric.centile !== AlignmentCentile.Spend)
                    .filter(metric => metric.isSelected())
                    .map(metric => storeRetailCentre[metric.centile]);
                if (includeSpend && spendAlignmentMetric?.isSelected()) {
                    storeCentiles.push(storeRetailCentre.spendCentile);
                }
                const distanceToTarget = Number(distance(storeCentiles, pinnedLocationCentiles));
                const maxDistance = Number(sqrt(storeCentiles.length * (99 ** 2)));
                const similarityScore = (1 - (distanceToTarget / maxDistance));
                analogueStores.push(new AnalogueStore(
                    store,
                    storeRetailCentre,
                    scenarioCatchment,
                    similarityScore,
                    storeRetailCentre.affluenceCentile - pinnedLocation.retailCentre.affluenceCentile,
                    storeRetailCentre.ageCentile - pinnedLocation.retailCentre.ageCentile,
                    storeRetailCentre.childrenCentile - pinnedLocation.retailCentre.childrenCentile,
                    storeRetailCentre.diversityCentile - pinnedLocation.retailCentre.diversityCentile,
                    storeRetailCentre.urbanicityCentile - pinnedLocation.retailCentre.urbanicityCentile,
                    storeRetailCentre.areaHealthCentile - pinnedLocation.retailCentre.areaHealthCentile,
                    storeRetailCentre.footfallCentile - pinnedLocation.retailCentre.footfallCentile,
                    includeSpend ? (storeRetailCentre.spendCentile - pinnedLocation.retailCentre.spendCentile) : undefined
                ));
            }
        });

        return analogueStores;
    }
);

export const selectClosestFiveAnalogueStores = createSelector(
    selectAnalogueStores,
    selectAnalogueStoreGroups,
    (analogueStores, analogueStoreGroups) => {
        const selectedStoreGroupNames = analogueStoreGroups
            .filter(group => group.isSelected())
            .map(group => group.name);
        return analogueStores
            .filter(analogueStore => selectedStoreGroupNames.includes(analogueStore.store.group))
            .sort((A, B) => numberSortExpression(A.similarityScore, B.similarityScore, SortDirection.DESC))
            .slice(0, 5);
    }
);
export default overviewSlice;
