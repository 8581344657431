import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { median } from "mathjs";
import _ from "lodash";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { DataWrapper } from "domain/dataWrapper";
import { RagIndicator, RagIndicatorStatus } from "domain/ragIndicator";
import {
    selectCatchmentSpendTotals,
    selectClientRegistration,
    selectComparator,
    selectKPMGSpendCategories,
    selectStore
} from "modules/customer/insights/portfolioNew/portfolioSlice";
import { GeoIndicator } from "modules/customer/insights/portfolioNew/store";
import { logError } from "modules/helpers/logger/loggerSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";

import {
    AgeStructureGB,
    AgeStructureNI,
    CatchmentDemographics,
    ChildrenAgeStructure,
    CountryOfBirth,
    DemographicIndicator,
    DemographicIndicatorMeasure,
    DwellingType,
    EducationLevel,
    Ethnicity,
    FirstLanguage,
    HouseholdIncome,
    HouseholdNumberOfCars,
    HouseholdOccupancy,
    HouseholdTenure,
    loadCatchmentDemographics,
    PopulationDensity
} from "./catchmentDemographics";
import { CustomerProfileDefinition, loadCustomerProfileDefinitions } from "./customerProfileDefinition";
import {
    CustomerProfilesTreemapSeries,
    CustomerProfileSummary,
    loadCustomerProfileSummaries
} from "./customerProfileSummary";
import { loadSelectedStoreSpendCategories, SpendCategory } from "./spendCategory";
import { SpendPerOutputArea, loadSpendPerOutputArea } from "./spendPerOutputArea";

interface LoadCatchmentResponse {
    customerProfileSummaries: CustomerProfileSummary[],
    customerProfileDefinitions: CustomerProfileDefinition[],
    spendPerOutputArea: SpendPerOutputArea[],
    spendCategories: SpendCategory[],
    catchmentDemographics: CatchmentDemographics[]
}

export interface CustomerProfilesTreemapRow {
    name: string,
    type: "Supergroup" | "Group" | "Subgroup",
    supergroupCode?: number | undefined,
    parent: string | undefined,
    numberOfVisitors: number,
    percentageOverallVisitors: number
}

interface CatchmentState {
    isLoading: boolean,
    hasErrors: boolean,
    customerProfileSummaries: CustomerProfileSummary[],
    customerProfilesTreemapSeries: CustomerProfilesTreemapSeries,
    customerProfileDefinitions: CustomerProfileDefinition[],
    spendPerOutputArea: SpendPerOutputArea[],
    spendCategories: SpendCategory[],
    weightSpendByProbability: boolean,
    catchmentDemographics: CatchmentDemographics[],
    demographicIndicator: DemographicIndicator,
    demographicIndicatorMeasure: DemographicIndicatorMeasure
}

const initialState: CatchmentState = {
    isLoading: false,
    hasErrors: false,
    customerProfileSummaries: [],
    customerProfilesTreemapSeries: CustomerProfilesTreemapSeries.Store,
    customerProfileDefinitions: [],
    spendPerOutputArea: [],
    spendCategories: [],
    weightSpendByProbability: true,
    catchmentDemographics: [],
    demographicIndicator: DemographicIndicator.AgeStructureGB,
    demographicIndicatorMeasure: DemographicIndicatorMeasure.PercentageOfPopulation
};

const catchmentSlice = createSlice({
    name: "customer/insights/portfolioNew/catchment",
    initialState,
    reducers: {
        setCustomerProfilesTreemapSeries: (state, action: PayloadAction<CustomerProfilesTreemapSeries>) => {
            state.customerProfilesTreemapSeries = action.payload;
        },
        clearCustomerProfilesTreemapSeries: (state) => {
            state.customerProfilesTreemapSeries = initialState.customerProfilesTreemapSeries;
        },
        toggleSpendCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const spendCategoryName = action.payload;
            state.spendCategories.find(spendCategory => spendCategory.name === spendCategoryName)?.toggleIsSelected();
        },
        chooseAllSpendCategories: (state) => {
            state.spendCategories.forEach(spendCategory => spendCategory.setIsSelected(true));
        },
        deselectAllSpendCategories: (state) => {
            state.spendCategories.forEach(spendCategory => spendCategory.setIsSelected(false));
        },
        clearCustomerProfileSummaries: (state) => {
            state.customerProfileSummaries = initialState.customerProfileSummaries;
        },
        clearCustomerProfileDefinitions: (state) => {
            state.customerProfileDefinitions = initialState.customerProfileDefinitions;
        },
        toggleWeightSpendByProbability: (state) => {
            state.weightSpendByProbability = !state.weightSpendByProbability;
        },
        clearWeightSpendByProbability: (state) => {
            state.weightSpendByProbability = initialState.weightSpendByProbability;
        },
        clearCatchmentDemographics: (state) => {
            state.catchmentDemographics = initialState.catchmentDemographics;
        },
        setDemographicIndicator: (state, action: PayloadAction<DemographicIndicator>) => {
            state.demographicIndicator = action.payload;
        },
        clearDemographicIndicator: (state) => {
            state.demographicIndicator = initialState.demographicIndicator;
        },
        setDemographicIndicatorMeasure: (state, action: PayloadAction<DemographicIndicatorMeasure>) => {
            state.demographicIndicatorMeasure = action.payload;
        },
        clearDemographicIndicatorMeasure: (state) => {
            state.demographicIndicatorMeasure = initialState.demographicIndicatorMeasure;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCatchment.pending, (state: CatchmentState) => {
            state.isLoading = true;
            state.hasErrors = false;
        });
        builder.addCase(loadCatchment.rejected, (state: CatchmentState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.customerProfileSummaries = initialState.customerProfileSummaries;
            state.customerProfileDefinitions = initialState.customerProfileDefinitions;
            state.spendPerOutputArea = initialState.spendPerOutputArea;
            state.spendCategories = initialState.spendCategories;
            state.catchmentDemographics = initialState.catchmentDemographics;
        });
        builder.addCase(loadCatchment.fulfilled, (state: CatchmentState, action: PayloadAction<LoadCatchmentResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.customerProfileSummaries = action.payload.customerProfileSummaries;
            state.customerProfileDefinitions = action.payload.customerProfileDefinitions;
            state.spendPerOutputArea = action.payload.spendPerOutputArea;
            state.spendCategories = action.payload.spendCategories;
            state.catchmentDemographics = action.payload.catchmentDemographics;
        });
    }
});

export const {
    setCustomerProfilesTreemapSeries,
    toggleSpendCategoryIsSelected,
    chooseAllSpendCategories,
    deselectAllSpendCategories,
    toggleWeightSpendByProbability,
    setDemographicIndicator,
    setDemographicIndicatorMeasure,
} = catchmentSlice.actions;

export const loadCatchment = createAppAsyncThunk(
    "customer/tools/portfolioNew/catchment/loadCatchment",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const selectedStore = selectStore(state);
            const comparator = selectComparator(state);
            const allRelevantSpendCategories = selectKPMGSpendCategories(state);
            const catchmentAccountId = selectClientRegistration(state)?.accountId ?? "";

            const comparatorStores = comparator?.getStores() ?? [];
            const selectedAndComparatorStores = selectedStore ? [selectedStore].concat(comparatorStores) : [];

            if (selectedStore?.geoIndicator === GeoIndicator.NI) {
                thunkAPI.dispatch(setDemographicIndicator(DemographicIndicator.AgeStructureNI));
            }
            const selectedStoreSpendCategoriesPromise = thunkAPI.dispatch(loadSelectedStoreSpendCategories(selectedStore));
            const customerProfileSummariesPromise = thunkAPI.dispatch(loadCustomerProfileSummaries(selectedAndComparatorStores, catchmentAccountId));
            const customerProfileDefinitionsPromise = thunkAPI.dispatch(loadCustomerProfileDefinitions());
            const spendPerOAPromise = thunkAPI.dispatch(loadSpendPerOutputArea(selectedStore, allRelevantSpendCategories, catchmentAccountId));
            const catchmentDemographicsPromise = thunkAPI.dispatch(loadCatchmentDemographics(selectedAndComparatorStores, catchmentAccountId));

            const results = await Promise.all([
                customerProfileSummariesPromise,
                customerProfileDefinitionsPromise,
                spendPerOAPromise,
                catchmentDemographicsPromise,
                selectedStoreSpendCategoriesPromise
            ]);

            const selectedStoreSpendCategories = results[4];
            const spendCategories = allRelevantSpendCategories.map(item =>
                new SpendCategory(item.name, selectedStoreSpendCategories?.includes(item.id)));

            const loadCatchmentResponse: LoadCatchmentResponse = {
                customerProfileSummaries: results[0],
                customerProfileDefinitions: results[1],
                spendPerOutputArea: results[2],
                spendCategories,
                catchmentDemographics: results[3]
            };
            return loadCatchmentResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Catchment.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearCatchment = (): AppThunk => (dispatch) => {
    dispatch(catchmentSlice.actions.clearCustomerProfileDefinitions());
    dispatch(catchmentSlice.actions.clearCustomerProfileSummaries());
    dispatch(catchmentSlice.actions.clearCustomerProfilesTreemapSeries());
    dispatch(catchmentSlice.actions.clearWeightSpendByProbability());
    dispatch(catchmentSlice.actions.clearCatchmentDemographics());
    dispatch(catchmentSlice.actions.clearDemographicIndicator());
    dispatch(catchmentSlice.actions.clearDemographicIndicatorMeasure());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.hasErrors;
};

export const selectCustomerProfileSummaries = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.customerProfileSummaries;
};

export const selectCustomerProfileDefinitions = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.customerProfileDefinitions;
};

export const selectCustomerProfilesTreemapSeries = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.customerProfilesTreemapSeries;
};

export const selectSpendPerOutputArea = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.spendPerOutputArea;
};

export const selectSpendCategories = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.spendCategories;
};

export const selectWeightSpendByProbability = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.weightSpendByProbability;
};

export const selectCatchmentDemographics = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.catchmentDemographics;
};

export const selectDemographicIndicator = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.demographicIndicator;
};

export const selectDemographicIndicatorMeasure = (state: RootState) => {
    return state.customer.insights.portfolioNew.catchment.demographicIndicatorMeasure;
};

export const selectWeightedPopulation = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectIsLoading,
    selectHasErrors,
    selectCustomerProfileSummaries,
    (selectedStore, comparator, isLoading, hasErrors, customerProfileSummaries) => {
        const weightedPopulation = {
            isLoading,
            hasErrors,
            data: { selectedStore: 0, comparator: 0 }
        };
        if (!selectedStore || !comparator || isLoading || hasErrors || customerProfileSummaries.length === 0) {
            return weightedPopulation;
        }

        weightedPopulation.data.selectedStore = customerProfileSummaries
            .filter(item => item.storeID === selectedStore.id)
            .reduce((total, current) => total + current.weightedPopulation, 0);

        const comparatorStoreIDs = comparator.getStores().map(store => store.id);

        const comparatorWeightedPopulations = _(customerProfileSummaries)
            .filter(item => comparatorStoreIDs.includes(item.storeID))
            .groupBy(item => item.storeID)
            .map(group =>
                group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.weightedPopulation, 0)
            ).value();

        weightedPopulation.data.comparator = comparatorWeightedPopulations.length > 0 ? median(comparatorWeightedPopulations) : 0;

        return weightedPopulation;
    }
);

export const selectUnweightedPopulation = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectIsLoading,
    selectHasErrors,
    selectCustomerProfileSummaries,
    (selectedStore, comparator, isLoading, hasErrors, customerProfileSummaries) => {
        const unweightedPopulation = {
            isLoading,
            hasErrors,
            data: { selectedStore: 0, comparator: 0 }
        };
        if (!selectedStore || !comparator || isLoading || hasErrors || customerProfileSummaries.length === 0) {
            return unweightedPopulation;
        }

        unweightedPopulation.data.selectedStore = customerProfileSummaries
            .filter(item => item.storeID === selectedStore.id)
            .reduce((total, current) => total + current.unweightedPopulation, 0);

        const comparatorStoreIDs = comparator.getStores().map(store => store.id);

        const comparatorUnweightedPopulations = _(customerProfileSummaries)
            .filter(item => comparatorStoreIDs.includes(item.storeID))
            .groupBy(item => item.storeID)
            .map(group =>
                group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.unweightedPopulation, 0)
            ).value();

        unweightedPopulation.data.comparator = comparatorUnweightedPopulations.length > 0 ? median(comparatorUnweightedPopulations) : 0;

        return unweightedPopulation;

    }
);

export const selectCustomerProfileBreakdown = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectIsLoading,
    selectHasErrors,
    selectCustomerProfileSummaries,
    (selectedStore, comparator, isLoading, hasErrors, customerProfileSummaries) => {
        const selectedStoreBreakdown: CustomerProfilesTreemapRow[] = [];
        const comparatorBreakdown: CustomerProfilesTreemapRow[] = [];
        const customerProfileBreakdown = {
            isLoading,
            hasErrors,
            data: { selectedStore: selectedStoreBreakdown, comparator: comparatorBreakdown }
        };
        if (!selectedStore || !comparator || isLoading || hasErrors || customerProfileSummaries.length === 0) {
            return customerProfileBreakdown;
        }

        const selectedStoreCustomerProfiles = customerProfileSummaries.filter(row => row.storeID === selectedStore.id);

        const totalPopulation = selectedStoreCustomerProfiles.reduce((total, current) => total + current.weightedPopulation, 0);

        customerProfileBreakdown.data.selectedStore = _(selectedStoreCustomerProfiles)
            .groupBy(item => item.supergroupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.weightedPopulation, 0);
                const aggregatedSupergroup: CustomerProfilesTreemapRow = {
                    name,
                    supergroupCode: group[0].supergroupCode,
                    type: "Supergroup",
                    parent: undefined,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / totalPopulation)
                };
                return aggregatedSupergroup;
            }).value();

        customerProfileBreakdown.data.selectedStore = customerProfileBreakdown.data.selectedStore.concat(_(selectedStoreCustomerProfiles)
            .groupBy(item => item.groupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.weightedPopulation, 0);
                const aggregatedGroup: CustomerProfilesTreemapRow = {
                    name,
                    type: "Group",
                    parent: group[0].supergroupName,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / totalPopulation)
                };
                return aggregatedGroup;
            }).value()
        );

        customerProfileBreakdown.data.selectedStore = customerProfileBreakdown.data.selectedStore.concat(_(selectedStoreCustomerProfiles)
            .groupBy(item => item.subgroupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.weightedPopulation, 0);
                const aggregatedSubgroup: CustomerProfilesTreemapRow = {
                    name,
                    type: "Subgroup",
                    parent: group[0].groupName,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / totalPopulation)
                };
                return aggregatedSubgroup;
            }).value()
        );

        const comparatorStoreIDs = comparator.getStores().map(item => item.id);
        const comparatorCustomerProfiles = customerProfileSummaries.filter(item => comparatorStoreIDs.includes(item.storeID));

        const comparatorMedianSubgroups = _(comparatorCustomerProfiles)
            .groupBy(item => item.subgroupName)
            .map((group, name) => {
                const weightedPopulationValues = group.map(item => item.weightedPopulation);
                return {
                    name,
                    supergroupCode: group[0].supergroupCode,
                    supergroupName: group[0].supergroupName,
                    groupName: group[0].groupName,
                    subgroupName: group[0].subgroupName,
                    medianWeightedPopulation: median(weightedPopulationValues),
                };
            }).value();

        const comparatorMedianTotalPopulation = comparatorMedianSubgroups.reduce((total, current) => total + current.medianWeightedPopulation, 0);

        customerProfileBreakdown.data.comparator = _(comparatorMedianSubgroups)
            .groupBy(item => item.supergroupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.medianWeightedPopulation, 0);
                const aggregatedSupergroup: CustomerProfilesTreemapRow = {
                    name,
                    supergroupCode: group[0].supergroupCode,
                    type: "Supergroup",
                    parent: undefined,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / comparatorMedianTotalPopulation)
                };
                return aggregatedSupergroup;
            }).value();

        customerProfileBreakdown.data.comparator = customerProfileBreakdown.data.comparator.concat(_(comparatorMedianSubgroups)
            .groupBy(item => item.groupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.medianWeightedPopulation, 0);
                const aggregatedGroup: CustomerProfilesTreemapRow = {
                    name,
                    type: "Group",
                    parent: group[0].supergroupName,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / comparatorMedianTotalPopulation)
                };
                return aggregatedGroup;
            }).value()
        );

        customerProfileBreakdown.data.comparator = customerProfileBreakdown.data.comparator.concat(_(comparatorMedianSubgroups)
            .groupBy(item => item.subgroupName)
            .map((group, name) => {
                const numberOfVisitors = group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.medianWeightedPopulation, 0);
                const aggregatedSubgroup: CustomerProfilesTreemapRow = {
                    name,
                    type: "Subgroup",
                    parent: group[0].groupName,
                    numberOfVisitors,
                    percentageOverallVisitors: 100 * (numberOfVisitors / comparatorMedianTotalPopulation)
                };
                return aggregatedSubgroup;
            }).value()
        );

        return customerProfileBreakdown;

    }
);

export interface AggregatedSpendPerOutputArea {
    outputAreaCode: string,
    likelihoodOfVisiting: number,
    supergroupName: string,
    spend: number,
    spendPerHead: number,
    spendByCategory: {
        categoryName: string,
        spend: number,
    }[]
}

export const selectAggregatedSpendByOutputArea = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSpendPerOutputArea,
    selectSpendCategories,
    selectWeightSpendByProbability,
    (isLoading, hasErrors, spendByOutputAreas, spendCategories, weightSpendByProbability) => {
        if (isLoading || hasErrors) {
            return [];
        }

        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedSpendCategoriesNames = selectedSpendCategories.map(spendCategory => spendCategory.name);
        return _(spendByOutputAreas)
            .filter(spendByOutputArea => selectedSpendCategoriesNames.includes(spendByOutputArea.categoryName))
            .groupBy(spendByOutputArea => spendByOutputArea.outputAreaCode)
            .map((group, key) => {
                const weight = weightSpendByProbability ? group[0].probability : 1;
                return {
                    outputAreaCode: key,
                    likelihoodOfVisiting: group[0].probability,
                    supergroupName: group[0].supergroupName,
                    spend: group.reduce((accumulator, spendByOutputArea) => accumulator + spendByOutputArea.spend * weight, 0),
                    spendPerHead: group.reduce((accumulator, spendByOutputArea) => accumulator + spendByOutputArea.spendPerHead * weight, 0),
                    spendByCategory: group.map(item => ({
                        categoryName: item.categoryName,
                        spend: item.spend * weight
                    }))
                };
            })
            .value() as AggregatedSpendPerOutputArea[];
    }
);

export const selectFilteredCatchmentTotalSpend = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    (state: RootState) => selectCatchmentSpendTotals(state),
    selectIsLoading,
    selectHasErrors,
    selectSpendCategories,
    selectWeightSpendByProbability,
    (selectedStore, comparator, catchmentSpendTotals, isLoading, hasErrors, spendCategories, weightSpendByProbability) => {
        const filteredCatchmentTotalSpend: DataWrapper<{ storeID: string, totalSpend: number }[]> = {
            isLoading: isLoading || catchmentSpendTotals.isLoading,
            hasErrors: hasErrors || catchmentSpendTotals.hasErrors,
            data: []
        };
        if (filteredCatchmentTotalSpend.isLoading || filteredCatchmentTotalSpend.hasErrors) {
            return filteredCatchmentTotalSpend;
        }

        const selectedSpendCategories = spendCategories.filter(spendCategory => spendCategory.isSelected());
        const selectedSpendCategoriesNames = selectedSpendCategories.map(spendCategory => spendCategory.name);


        filteredCatchmentTotalSpend.data = _(catchmentSpendTotals.data)
            .filter(item => selectedSpendCategoriesNames.includes(item.kpmgSpendCategory))
            .groupBy(item => item.storeID)
            .map((group, key) => ({
                storeID: key,
                totalSpend: group.reduce((accumulator, item) => {
                    const spend = weightSpendByProbability ? item.weightedSpend : item.unweightedSpend;
                    return accumulator + spend;
                }, 0)
            }))
            .value();

        return filteredCatchmentTotalSpend;
    }
);

export const selectCatchmentTotalYearlySpend = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectFilteredCatchmentTotalSpend,
    (selectedStore, comparator, filteredSpend) => {
        const catchmentTotalYearlySpend: DataWrapper<{ selectedStore: number, comparator: number }> = {
            isLoading: filteredSpend.isLoading,
            hasErrors: filteredSpend.hasErrors,
            data: {
                selectedStore: 0,
                comparator: 0
            }
        };
        if (catchmentTotalYearlySpend.isLoading || catchmentTotalYearlySpend.hasErrors) {
            return catchmentTotalYearlySpend;
        }

        catchmentTotalYearlySpend.data.selectedStore =
            filteredSpend.data.find(item => item.storeID === selectedStore?.id)?.totalSpend ?? 0;

        const comparatorStoreIDs = comparator?.getStores().map(store => store.id);
        const comparatorTotalCatchmentSpendByStore =
            filteredSpend.data.filter(item => comparatorStoreIDs?.includes(item.storeID));

        catchmentTotalYearlySpend.data.comparator = comparatorTotalCatchmentSpendByStore.length !== 0
            ? median(comparatorTotalCatchmentSpendByStore.map(item => item.totalSpend))
            : 0;
        return catchmentTotalYearlySpend;
    }
);

export const selectCatchmentSpendPerHead = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectFilteredCatchmentTotalSpend,
    selectCustomerProfileSummaries,
    (selectedStore, comparator, filteredSpend, customerProfileSummaries) => {
        const catchmentSpendPerHead: DataWrapper<{ selectedStore: number, comparator: number }> = {
            isLoading: filteredSpend.isLoading,
            hasErrors: filteredSpend.hasErrors,
            data: {
                selectedStore: 0,
                comparator: 0
            }
        };
        if (catchmentSpendPerHead.isLoading || catchmentSpendPerHead.hasErrors) {
            return catchmentSpendPerHead;
        }

        const unweightedPopulationByStore = _(customerProfileSummaries)
            .groupBy(item => item.storeID)
            .map((group, storeID) => ({
                storeID,
                unweightedPopulation: group.reduce((accumulator, customerProfileSummary) => accumulator + customerProfileSummary.unweightedPopulation, 0)
            })).value();

        const spendPerHeadByStore = filteredSpend.data.map(spendByStore => {
            const relevantStorePopulation = unweightedPopulationByStore.find(item => item.storeID === spendByStore.storeID);
            return {
                storeID: spendByStore.storeID,
                spendPerHead: spendByStore.totalSpend / (relevantStorePopulation?.unweightedPopulation ?? 0)
            };
        });

        catchmentSpendPerHead.data.selectedStore =
            spendPerHeadByStore.find(item => item.storeID === selectedStore?.id)?.spendPerHead ?? 0;

        const comparatorStoreIDs = comparator?.getStores().map(store => store.id);
        const comparatorSpendPerHeadByStore =
            spendPerHeadByStore.filter(item => comparatorStoreIDs?.includes(item.storeID));

        catchmentSpendPerHead.data.comparator = comparatorSpendPerHeadByStore.length !== 0
            ? median(comparatorSpendPerHeadByStore.map(item => item.spendPerHead))
            : 0;
        return catchmentSpendPerHead;
    }
);

export const selectWeightedSpendOrSpendPerHead = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectCatchmentSpendPerHead,
    selectSpendCategories,
    selectWeightSpendByProbability,
    (selectedStore, comparator, catchmentSpendPerHead, spendCategories, isWeighted) => {
        const prefix = isWeighted ? "Weighted spend" : "Spend";
        const id = "weighted-spend-or-spend-per-head";
        const label = prefix + " per head";
        let value = "";
        let status = RagIndicatorStatus.Info;
        const { isLoading, hasErrors, data } = catchmentSpendPerHead;
        const selectedSpendCategories = spendCategories.filter(category => category.isSelected());
        if (isLoading || hasErrors || !selectedStore || !comparator || selectedSpendCategories.length === 0) {
            return new RagIndicator(id, status, label, value, isLoading, hasErrors);
        }

        const percentageDifference = mathUtils.safePercentageChange(data.selectedStore, data.comparator);

        if (percentageDifference > 50) {
            status = RagIndicatorStatus.Green;
            value = `${prefix} per head in ${selectedStore.name} is markedly larger than that of ${comparator.name} median`;
        } else if (percentageDifference < -50) {
            status = RagIndicatorStatus.Red;
            value = `${prefix} per head in ${selectedStore.name} is markedly smaller than that of ${comparator.name} median`;
        } else {
            status = RagIndicatorStatus.Amber;
            value = `${prefix} per head in ${selectedStore.name} is broadly in line with that of ${comparator.name} median`;
        }
        return new RagIndicator(id, status, label, value);
    }
);

export const selectCatchmentDemographicIndicators = createSelector(
    (state: RootState) => selectStore(state),
    (state: RootState) => selectComparator(state),
    selectIsLoading,
    selectHasErrors,
    selectCatchmentDemographics,
    (selectedStore, comparator, isLoading, hasErrors, catchmentDemographics) => {
        const catchmentDemographicIndicators: DataWrapper<{
            selectedStore?: CatchmentDemographics,
            comparator?: CatchmentDemographics
        }> = {
            isLoading,
            hasErrors,
            data: {
                selectedStore: undefined,
                comparator: undefined
            }
        };
        if (catchmentDemographicIndicators.isLoading || catchmentDemographicIndicators.hasErrors) {
            return catchmentDemographicIndicators;
        }

        catchmentDemographicIndicators.data.selectedStore =
            catchmentDemographics.find(item => item.storeID === selectedStore?.id);

        const comparatorStoreIDs = comparator?.getStores().map(store => store.id);
        const comparatorCatchmentDemographics = catchmentDemographics.filter(item => comparatorStoreIDs?.includes(item.storeID));

        const nullableMedian = (values: (number | null)[]) => {
            const filteredValues = values.filter(value => value !== null) as number[];
            return filteredValues.length !== 0 ? median(filteredValues) : null;
        };

        if (comparatorCatchmentDemographics.length !== 0) {
            catchmentDemographicIndicators.data.comparator = new CatchmentDemographics(
                "comparator",
                new DwellingType(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.dwellingType.detached)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.dwellingType.flat)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.dwellingType.terrace)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.dwellingType.semiDetached)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.dwellingType.otherAccommodation))
                ),
                new HouseholdTenure(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdTenure.ownedOutright)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdTenure.ownedWithLoan)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdTenure.socialRent)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdTenure.privateRent)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdTenure.sharedOwnership)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdTenure.livingRentFree))
                ),
                new ChildrenAgeStructure(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.childrenAgeStructure.childrenAgeLessThan1)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.childrenAgeStructure.childrenAge1to2)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.childrenAgeStructure.childrenAge3to5)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.childrenAgeStructure.childrenAge6to10)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.childrenAgeStructure.childrenAge11to16))
                ),
                new HouseholdOccupancy(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdOccupancy.occupancyRating2Plus)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdOccupancy.occupancyRating1)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdOccupancy.occupancyRating0)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdOccupancy.occupancyRatingMinus1)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdOccupancy.occupancyRatingMinus2orLess))
                ),
                new AgeStructureGB(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureGB.age0to16)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureGB.age17to25)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureGB.age26to35)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureGB.age36to45)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureGB.age46to55)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureGB.age56to65)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureGB.age66Plus))
                ),
                new AgeStructureNI(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureNI.age0to15)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureNI.age16to24)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureNI.age25to44)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureNI.age45to64)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ageStructureNI.age65Plus))
                ),
                new CountryOfBirth(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.countryOfBirth.bornInUK)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.countryOfBirth.emigratedToUK))
                ),
                new Ethnicity(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.arab)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.asianOther)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.bangladeshi)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.blackAfrican)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.blackCaribbean)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.blackOther)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.chinese)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.indian)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.mixed)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.other)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.pakistani)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.ethnicity.white)),
                ),
                new FirstLanguage(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.firstLanguage.englishOrWelsh)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.firstLanguage.other))
                ),
                new HouseholdNumberOfCars(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdNumberOfCars.car3Plus)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdNumberOfCars.car2)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdNumberOfCars.car1)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdNumberOfCars.car0))
                ),
                new PopulationDensity(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.populationDensity.popDensityLess2000)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.populationDensity.popDensity2000to3999)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.populationDensity.popDensity4000to5999)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.populationDensity.popDensity6000to10000)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.populationDensity.popDensity10000Plus))
                ),
                new HouseholdIncome(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome100kPlus)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome90to100k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome80to90k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome70to80k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome60to70k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome50to60k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome40to50k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome30to40k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncome20to30k)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.householdIncome.countIncomeSub20k))
                ),
                new EducationLevel(
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.educationLevel.noQualification)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.educationLevel.level1)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.educationLevel.level2)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.educationLevel.level3)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.educationLevel.level4Plus)),
                    nullableMedian(comparatorCatchmentDemographics.map(item => item.educationLevel.otherQualification)),
                )
            );
        }

        return catchmentDemographicIndicators;
    }
);

export const selectDemographicIndicatorDropdownOptions = createSelector(
    (state: RootState) => selectStore(state),
    (selectedStore) => {
        const demographicIndicators = selectedStore?.geoIndicator === GeoIndicator.GB ? new Map<DemographicIndicator, string>([
            [DemographicIndicator.HouseholdIncome, "Household income (Affluence)"],
            [DemographicIndicator.HouseholdOccupancy, "Household occupancy (Affluence)"],
            [DemographicIndicator.HouseholdTenure, "Household tenure (Affluence)"],
            [DemographicIndicator.AgeStructureGB, "Age structure (Age)"],
            [DemographicIndicator.ChildrenAgeStructure, "Children's age structure (Children)"],
            [DemographicIndicator.CountryOfBirth, "Country of birth (Diversity)"],
            [DemographicIndicator.Ethnicity, "Ethnicity (Diversity)"],
            [DemographicIndicator.FirstLanguage, "First language (Diversity)"],
            [DemographicIndicator.DwellingType, "Dwelling type (Urbanicity)"],
            [DemographicIndicator.HouseholdNumberOfCars, "Household number of cars (Urbanicity)"],
            [DemographicIndicator.PopulationDensity, "Population density (Urbanicity)"],
            [DemographicIndicator.EducationLevel, "Education level"]
        ]) : new Map<DemographicIndicator, string>([
            [DemographicIndicator.HouseholdTenure, "Household tenure (Affluence)"],
            [DemographicIndicator.AgeStructureNI, "Age structure (Age)"],
            [DemographicIndicator.CountryOfBirth, "Country of birth (Diversity)"],
            [DemographicIndicator.Ethnicity, "Ethnicity (Diversity)"],
            [DemographicIndicator.DwellingType, "Dwelling type (Urbanicity)"],
            [DemographicIndicator.HouseholdNumberOfCars, "Household number of cars (Urbanicity)"],
            [DemographicIndicator.PopulationDensity, "Population density (Urbanicity)"]
        ]);

        return demographicIndicators;
    }
);

export default catchmentSlice;
