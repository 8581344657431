import { useTheme, withStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { DataGridPro, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import Spacer from "components/Spacer";
import { selectUserInfo } from "modules/auth/authSlice";
import { selectCostReductionOpportunityByStore, selectSimilarityMetricColumns } from "modules/customer/insights/cost/costSlice";
import React from "react";
import { useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledDataGrid = withStyles(theme => ({
    root: {
        border: 'none',
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        '& .MuiCheckbox-root': {
            color: theme.palette.text.primary,
            '&.Mui-checked': {
                //@ts-ignore
                color: theme.palette.tertiary.main,
            },
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.16)',
            },
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        }
    }
}))(DataGridPro);

const CostReductionOpportunitiesByStore: React.FC = () => {
    const theme = useTheme();
    const userInfo = useAppSelector(selectUserInfo);
    const { data: rows, isLoading } = useAppSelector(selectCostReductionOpportunityByStore);
    const similarityMetricsColumns = useAppSelector(selectSimilarityMetricColumns);

    const columns: GridColDef[] = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Store name',
            sortable: true,
        },
        {
            field: 'storeId',
            flex: 1,
            headerName: 'Store id',
            sortable: true,
            hide: true
        },
        {
            field: 'group',
            flex: 1,
            headerName: 'Store type',
            type: 'singleSelect',
            sortable: true,
        },
        {
            field: 'region',
            flex: 1,
            headerName: 'Region',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'clientRegion',
            flex: 1,
            headerName: `${userInfo.companyDisplayName} region`,
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'pitchType',
            flex: 1,
            headerName: 'Pitch type',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'format',
            flex: 1,
            headerName: 'Format type',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'segment',
            flex: 1,
            headerName: 'Segment type',
            type: 'singleSelect',
            sortable: true,
            hide: true
        },
        {
            field: 'sizeInSquareFeet',
            flex: 1,
            headerName: 'Sqftage',
            type: 'number',
            sortable: true,
            hide: true
        },
        {
            field: 'openingDate',
            type: 'date',
            flex: 1,
            headerName: 'Opening date',
            sortable: true,
            hide: true
        },
        {
            field: 'numberOfEmployees',
            flex: 1,
            headerName: 'Employees',
            type: 'number',
            sortable: true,
            hide: true
        },
        {
            field: 'costName',
            type: 'singleSelect',
            flex: 1,
            headerName: 'Cost type',
            sortable: true,
        },
        {
            field: 'costValue',
            type: 'number',
            flex: 1,
            headerName: 'Cost value year to date',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
        },
        {
            field: 'costAsPercentageOfRevenue',
            type: 'number',
            flex: 1,
            headerName: 'Cost value as percentage of revenue',
            sortable: true,
            renderCell: (params) => numberFormatter.toPercentage(params.value ?? 0, false),
            hide: true
        },
        {
            field: 'revenue',
            type: 'number',
            flex: 1,
            headerName: 'Revenue in the last year',
            sortable: true,
            renderCell: (params) => numberFormatter.toGBP(params.value ?? 0, 1),
            hide: true
        },
        {
            field: 'opportunityValue',
            type: 'number',
            flex: 1,
            headerName: 'Opportunity value',
            sortable: true,
            renderCell: (params) => {
                const absoluteValueString = numberFormatter.toGBP(params.value ?? 0, 1);
                const percentageValue = params.getValue(params.id, 'opportunityValueAsPercentageOfRevenue');
                const percentageValueString = numberFormatter.toPercentage(percentageValue ?? 0, false);
                return `${absoluteValueString} (${percentageValueString})`;
            }
        },
        {
            field: 'opportunityValueAsPercentageOfRevenue',
            type: 'number',
            flex: 1,
            headerName: 'Opportunity value as percentage of revenue',
            sortable: true,
            renderCell: (params) => numberFormatter.toPercentage(params.value ?? 0, false),
            hide: true
        },
        ...similarityMetricsColumns
    ];

    const columndsWithWidth = columns.map(column => {
        if (column.type === 'singleSelect') {
            //@ts-expect-error
            column.valueOptions = Array.from(new Set(rows.map(row => row[column.field])));
        }
        return {
            ...column,
            minWidth: 200
        };
    });

    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ paddingRight: theme.spacing(2) }}>
                <GridToolbarColumnsButton style={{ color: theme.palette.text.primary }} />
                <GridToolbarFilterButton style={{ color: theme.palette.text.primary }} />
                <Spacer />
                {/* @ts-ignore */}
                <GridToolbarExport style={{ color: theme.palette.tertiary.main }} />
            </GridToolbarContainer>
        );
    }

    return (
        <StyledDataGrid
            rows={rows}
            columns={columndsWithWidth}
            checkboxSelection
            disableSelectionOnClick
            loading={isLoading}
            components={{
                Toolbar: CustomToolbar,
                ColumnSortedAscendingIcon: ExpandLess,
                ColumnSortedDescendingIcon: ExpandMore
            }}
            sortingOrder={['asc', 'desc']}
            hideFooter
        />
    );
};

export default CostReductionOpportunitiesByStore;
