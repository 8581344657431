import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class ForecastSales {
    public readonly storeId: string;
    public readonly forecastSales: number;

    constructor(
        storeId: string,
        forecastSales: number
    ) {
        this.storeId = storeId;
        this.forecastSales = forecastSales;
    }
}

export const loadForecastSales = (): AppThunk<Promise<ForecastSales[]>> => async (dispatch) => {
    try {
        const storesQuery = {
            measures: [
                "F_SalesForecast.SumLineValue"
            ],
            segments: [
                "F_SalesForecast.ForecastOnly",
                "D_Store.OpenPhysicalStores"
            ],
            dimensions: [
                "D_Store.StoreNaturalID"
            ],
            limit: 50000
        };
        const resultSetStores = await dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
        return resultSetStores.rawData().map(row => new ForecastSales(
            row["D_Store.StoreNaturalID"],
            row["F_SalesForecast.SumLineValue"],
        ));
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};
