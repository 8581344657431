import cubejs, { CubeApi, Query, ResultSet } from "@cubejs-client/core";
import { chunk } from "lodash";
import { max } from "mathjs";

export class CubeService {
    private readonly cubeApi: CubeApi;

    constructor(apiUrl: string, accessToken: string) {
        const resType = 'compact';
        this.cubeApi = cubejs(accessToken, { apiUrl, resType });
    }

    async load(query: Query) {
        query.responseFormat = 'compact';
        return await this.cubeApi.load(query);
    }

    async loadParallel(query: Query, rowsCount: number, preserveResultSets?: boolean): Promise<any[]> {
        const promisesCount = (rowsCount / 50000);
        const promises: Promise<ResultSet>[] = [];
        for (let i = 0; i <= promisesCount; i++) {
            const currentQuery: Query = {
                ...query,
                limit: 50000,
                offset: i * 50000,
                responseFormat: 'compact'
            };
            const promise = this.cubeApi.load(currentQuery);
            promises.push(promise);
        }
        const resultSets = await Promise.all(promises);
        if (preserveResultSets) {
            return resultSets;
        }
        return resultSets.map(resultSet => resultSet.rawData()).flat();
    }

    async loadExtended(query: any) {
        query.responseFormat = 'compact';
        let resultSet: any = {
            loadResponses: []
        };
        let resultSetFinal: any = {
            loadResponses: []
        };
        let responseLength = 0;

        const responseLimit = 50000;
        const chunkLength = 2000;
        let filterNo: any = 0;
        let needsChunked = false;
        let filterArrayChunks;

        if (!query.limit) {
            query.limit = responseLimit;
        }

        do {
            if (!query.offset) {
                query.offset = 0;
            }

            if (query.filters) { //Check for any filters that exceed the CubeJS limit of 2000
                for (let i in query.filters) {
                    if (query.filters[i].values) {
                        if (query.filters[i].values.length > chunkLength) {
                            needsChunked = true;
                            filterArrayChunks = chunk(query.filters[i].values, chunkLength);
                            filterNo = i;
                        }
                    }
                }

                if (needsChunked) {
                    let resultSetChunk;
                    for (let i in filterArrayChunks) {
                        // @ts-ignore
                        const arrayChunk = filterArrayChunks[i];
                        query.filters[filterNo].values = arrayChunk;

                        resultSetChunk = await this.cubeApi.load(query);

                        // @ts-ignore
                        for (let j in resultSetChunk.loadResponses) {
                            if (resultSet.loadResponses[j]) {
                                // @ts-ignore
                                resultSet.loadResponses[j].data.push(...resultSetChunk.loadResponses[j].data);
                            } else {
                                // @ts-ignore
                                resultSet.loadResponses[j] = resultSetChunk.loadResponses[j];
                            }
                        }
                    }
                } else {
                    resultSet = await this.cubeApi.load(query);
                }
            } else {
                resultSet = await this.cubeApi.load(query);
            }
            responseLength = 0;
            for (let i in resultSet.loadResponses) {
                if (resultSetFinal.loadResponses[i]) {
                    resultSetFinal.loadResponses[i].data.push(...resultSet.loadResponses[i].data);
                } else {
                    resultSetFinal.loadResponses[i] = resultSet.loadResponses[i];
                }
                responseLength = max(resultSet.loadResponses[i].data.length, responseLength);
            }
            query.offset += responseLimit;
        } while (responseLength === responseLimit); //If responses are equal to cube limit then increase offset and query again until all records are returned

        return resultSetFinal;
    }

    async loadCatchmentArea(query: any, catchmentAccountId: string, catchmentSchemaName: string) {
        query.responseFormat = 'compact';
        query.filters.push({
            member: `${catchmentSchemaName}.Client_ID`,
            operator: "equals",
            values: [catchmentAccountId]
        });
        return await this.loadExtended(query);
    }

    async sql(query: Query) {
        return await this.cubeApi.sql(query);
    }
}
