import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "appThunk";
import { Comparator, ComparatorType } from "modules/customer/insights/portfolioNew/comparator";
import { Store, StoreWithSimilarityScore } from "modules/customer/insights/portfolioNew/store";
import { selectStores as selectPortfolioStores, selectShowSimilarityScores } from "modules/customer/insights/portfolioNew/portfolioSlice";
import { RootState } from "store";
import mathUtils from "utils/mathUtils";
import { dateSortExpression, numberSortExpression, SortDirection, stringSortExpression } from "utils/sortUtils";

interface FiltersVisibility {
    isVisible: boolean
}

interface SliderThresholds {
    minPercentileThreshold: number,
    maxPercentileThreshold: number,
    percentileThresholds: number[]
}

export enum FilterStep {
    SelectStore,
    SelectComparator,
    CustomSelection
}

export interface StoresSearch {
    name: string
}

export interface StoresFilter {
    clientRegion: string,
    region: string,
    storeCategory: string,
    pitchType: string,
    groupType: string,
    formatType: string,
    segmentType: string,
    openingDateFrom?: Date,
    openingDateTo?: Date,
    storeSize: number[],
    numberOfEmployees: number[],
    revenue: number[],
    grossProfitMargin: number[],
    revenuePerSquareFoot: number[],
    catchmentSize: number[],
    numberOfCompetitors: number[],
    changeInNumberOfStores: number[],
    footfallLevel: number[]
}

export enum StoreSortField {
    RadarColour,
    StoreName,
    OpeningDate,
    StoreSize,
    Employees,
    Revenue,
    GrossProfitMargin,
    RevenuePerSquareFoot,
    CatchmentSize,
    NumberOfCompetitors,
    ChangeInNumberOfStores,
    FootfallLevel
}

interface StoresSort {
    field: StoreSortField,
    direction: SortDirection
}

export interface CustomSelectionStoresFilter {
    clientRegion: string,
    region: string,
    storeCategory: string,
    pitchType: string,
    groupType: string,
    formatType: string,
    segmentType: string,
    openingDateFrom?: Date,
    openingDateTo?: Date,
    similarity: number[],
    storeSize: number[],
    numberOfEmployees: number[],
    revenue: number[],
    grossProfitMargin: number[],
    revenuePerSquareFoot: number[],
    catchmentSize: number[],
    numberOfCompetitors: number[],
    changeInNumberOfStores: number[],
    footfallLevel: number[]
}

export enum CustomSelectionStoreSortField {
    Similarity,
    RadarColour,
    StoreName,
    OpeningDate,
    StoreSize,
    Employees,
    Revenue,
    GrossProfitMargin,
    RevenuePerSquareFoot,
    CatchmentSize,
    NumberOfCompetitors,
    ChangeInNumberOfStores,
    FootfallLevel
}

interface CustomSelectionStoresSort {
    field: CustomSelectionStoreSortField,
    direction: SortDirection
}

interface FiltersState {
    filtersVisibility: FiltersVisibility,
    activeStep: FilterStep,
    candidateStore?: Store,
    candidateComparator?: Comparator,
    storesSearch: StoresSearch,
    storesFilter: StoresFilter,
    storesSort: StoresSort,
    customSelectionStoresSearch: StoresSearch,
    customSelectionStoresFilter: CustomSelectionStoresFilter,
    customSelectionStoresSort: CustomSelectionStoresSort
}

const initialState: FiltersState = {
    filtersVisibility: {
        isVisible: false
    },
    activeStep: FilterStep.SelectStore,
    storesSearch: {
        name: ""
    },
    storesFilter: {
        clientRegion: "",
        region: "",
        storeCategory: "",
        pitchType: "",
        groupType: "",
        formatType: "",
        segmentType: "",
        openingDateFrom: undefined,
        openingDateTo: undefined,
        storeSize: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        numberOfEmployees: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        revenue: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        grossProfitMargin: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        revenuePerSquareFoot: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        catchmentSize: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        numberOfCompetitors: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        changeInNumberOfStores: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        footfallLevel: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
    },
    storesSort: {
        field: StoreSortField.RadarColour,
        direction: SortDirection.DESC
    },
    customSelectionStoresSearch: {
        name: ""
    },
    customSelectionStoresFilter: {
        clientRegion: "",
        region: "",
        storeCategory: "",
        pitchType: "",
        groupType: "",
        formatType: "",
        segmentType: "",
        openingDateFrom: undefined,
        openingDateTo: undefined,
        similarity: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        storeSize: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        numberOfEmployees: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        revenue: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        grossProfitMargin: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        revenuePerSquareFoot: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        catchmentSize: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        numberOfCompetitors: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        changeInNumberOfStores: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
        footfallLevel: [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
    },
    customSelectionStoresSort: {
        field: CustomSelectionStoreSortField.Similarity,
        direction: SortDirection.DESC
    }
};

const filtersSlice = createSlice({
    name: "customer/insights/portfolio/filters",
    initialState,
    reducers: {
        showFilters: (state) => {
            state.activeStep = FilterStep.SelectStore;
            state.filtersVisibility.isVisible = true;
        },
        hideFilters: (state) => {
            state.filtersVisibility.isVisible = false;
        },
        setActiveStep: (state, action: PayloadAction<FilterStep>) => {
            state.activeStep = action.payload;
        },
        setCandidateStore: (state, action: PayloadAction<Store>) => {
            state.candidateStore = action.payload;
        },
        clearCandidateStore: (state) => {
            state.candidateStore = undefined;
        },
        setCandidateComparator: (state, action: PayloadAction<Comparator>) => {
            state.candidateComparator = action.payload;
        },
        clearCandidateComparator: (state) => {
            state.candidateComparator = undefined;
        },
        addStoreToCandidateComparator: (state, action: PayloadAction<StoreWithSimilarityScore>) => {
            if (!state.candidateComparator || state.candidateComparator.type !== ComparatorType.Custom) {
                return;
            }
            state.candidateComparator.addStore(action.payload);
        },
        removeStoreFromCandidateComparator: (state, action: PayloadAction<StoreWithSimilarityScore>) => {
            if (!state.candidateComparator || state.candidateComparator.type !== ComparatorType.Custom) {
                return;
            }
            state.candidateComparator.removeStore(action.payload);
        },
        setStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.storesSearch = action.payload;
        },
        clearStoresSearch: (state) => {
            state.storesSearch = initialState.storesSearch;
        },
        setStoresFilter: (state, action: PayloadAction<StoresFilter>) => {
            state.storesFilter = action.payload;
        },
        clearStoresFilter: (state) => {
            state.storesFilter = initialState.storesFilter;
        },
        setStoresSort: (state, action: PayloadAction<StoresSort>) => {
            state.storesSort = action.payload;
        },
        clearStoresSort: (state) => {
            state.storesSort = initialState.storesSort;
        },
        setCustomSelectionStoresSearch: (state, action: PayloadAction<StoresSearch>) => {
            state.customSelectionStoresSearch = action.payload;
        },
        clearCustomSelectionStoresSearch: (state) => {
            state.customSelectionStoresSearch = initialState.customSelectionStoresSearch;
        },
        setCustomSelectionStoresFilter: (state, action: PayloadAction<CustomSelectionStoresFilter>) => {
            state.customSelectionStoresFilter = action.payload;
        },
        clearCustomSelectionStoresFilter: (state) => {
            state.customSelectionStoresFilter = initialState.customSelectionStoresFilter;
        },
        setCustomSelectionStoresSort: (state, action: PayloadAction<CustomSelectionStoresSort>) => {
            state.customSelectionStoresSort = action.payload;
        },
        clearCustomSelectionStoresSort: (state) => {
            state.customSelectionStoresSort = initialState.customSelectionStoresSort;
        }
    }
});

export const {
    showFilters,
    hideFilters,
    setActiveStep,
    setCandidateStore,
    clearCandidateStore,
    setCandidateComparator,
    clearCandidateComparator,
    addStoreToCandidateComparator,
    removeStoreFromCandidateComparator,
    setStoresSearch,
    clearStoresSearch,
    setStoresFilter,
    clearStoresFilter,
    setStoresSort,
    clearStoresSort,
    setCustomSelectionStoresSearch,
    clearCustomSelectionStoresSearch,
    setCustomSelectionStoresFilter,
    clearCustomSelectionStoresFilter,
    setCustomSelectionStoresSort,
    clearCustomSelectionStoresSort
} = filtersSlice.actions;

export const clearFilters = (): AppThunk => async (dispatch) => {
    dispatch(filtersSlice.actions.hideFilters());
    dispatch(filtersSlice.actions.clearCandidateStore());
    dispatch(filtersSlice.actions.clearCandidateComparator());
    dispatch(filtersSlice.actions.clearStoresSearch());
    dispatch(filtersSlice.actions.clearStoresFilter());
    dispatch(filtersSlice.actions.clearStoresSort());
    dispatch(filtersSlice.actions.clearCustomSelectionStoresSearch());
    dispatch(filtersSlice.actions.clearCustomSelectionStoresFilter());
    dispatch(filtersSlice.actions.clearCustomSelectionStoresSort());
    dispatch(filtersSlice.actions.setActiveStep(FilterStep.SelectStore));
};

export const selectFiltersVisibility = (state: RootState): FiltersVisibility => {
    return state.customer.insights.portfolioNew.filters.filtersVisibility;
};

export const selectActiveStep = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.activeStep;
};

export const selectCandidateStore = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.candidateStore;
};

export const selectCandidateComparator = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.candidateComparator;
};

export const selectStoresSearch = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.storesSearch;
};

export const selectStoresFilter = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.storesFilter;
};

export const selectStoresSort = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.storesSort;
};

export const selectCustomSelectionStoresSearch = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.customSelectionStoresSearch;
};

export const selectCustomSelectionStoresFilter = (state: RootState) => {
    return state.customer.insights.portfolioNew.filters.customSelectionStoresFilter;
};

export const selectCustomSelectionStoresSort = (state: RootState) => {
    const showSimilarityScores = selectShowSimilarityScores(state);

    if (state.customer.insights.portfolioNew.filters.customSelectionStoresSort.field === CustomSelectionStoreSortField.Similarity && !showSimilarityScores) {
        return {
            field: CustomSelectionStoreSortField.RadarColour,
            direction: SortDirection.DESC
        };
    }

    return state.customer.insights.portfolioNew.filters.customSelectionStoresSort;
};

export const selectRegions = createSelector(
    selectPortfolioStores,
    (stores) => {
        const regions = stores
            .map(store => store.region)
            .sort((regionA, regionB) => stringSortExpression(regionA, regionB, SortDirection.ASC));
        return Array.from(new Set(regions));
    }
);

export const selectStoreCategories = createSelector(
    selectPortfolioStores,
    (stores) => {
        const storeCategories = stores
            .map(store => store.kpmgStoreCategory)
            .sort((categoryA, categoryB) => stringSortExpression(categoryA, categoryB, SortDirection.ASC));
        return Array.from(new Set(storeCategories));
    }
);

export const selectPitchTypes = createSelector(
    selectPortfolioStores,
    (stores) => {
        const pitchTypes = stores
            .map(store => store.retailCentreClassificationName)
            .sort((pitchTypeA, pitchTypeB) => stringSortExpression(pitchTypeA, pitchTypeB, SortDirection.ASC));
        return Array.from(new Set(pitchTypes));
    }
);

export const selectGroupTypes = createSelector(
    selectPortfolioStores,
    (stores) => {
        const storeGroups = stores
            .map(store => store.group)
            .sort((groupA, groupB) => stringSortExpression(groupA, groupB, SortDirection.ASC));

        return Array.from(new Set(storeGroups));
    }
);

export const selectFormatTypes = createSelector(
    selectPortfolioStores,
    (stores) => {
        const storeFormats = stores
            .map(store => store.format)
            .sort((groupA, groupB) => stringSortExpression(groupA, groupB, SortDirection.ASC));

        return Array.from(new Set(storeFormats));
    }
);

export const selectSegmentTypes = createSelector(
    selectPortfolioStores,
    (stores) => {
        const storeSegments = stores
            .map(store => store.segment)
            .sort((groupA, groupB) => stringSortExpression(groupA, groupB, SortDirection.ASC));

        return Array.from(new Set(storeSegments));
    }
);

export const selectClientRegions = createSelector(
    selectPortfolioStores,
    (stores) => {
        const storeSegments = stores
            .map(store => store.clientRegion)
            .sort((groupA, groupB) => stringSortExpression(groupA, groupB, SortDirection.ASC));

        return Array.from(new Set(storeSegments));
    }
);

export const selectFirstAndLatestStoreOpeningDate = createSelector(
    selectPortfolioStores,
    (stores) => {
        const data = {
            firstOpeningDate: new Date(0),
            lastOpeningDate: new Date(0),
        };

        const openingDates = stores
            .map(store => store.openingDate)
            .sort((dateA, dateB) => dateA.getTime() - dateB.getTime());

        if (openingDates.length > 0) {
            data.firstOpeningDate = openingDates[0];
            data.lastOpeningDate = openingDates[(openingDates.length - 1)];
        }

        return data;
    }
);

const generateSliderThresholds = (allValues: number[]): SliderThresholds => {
    const percentileThresholds = mathUtils.percentileThresholds(allValues, 5);
    percentileThresholds.push(allValues[(allValues.length - 1)]);
    return {
        minPercentileThreshold: percentileThresholds[0],
        maxPercentileThreshold: percentileThresholds[percentileThresholds.length - 1],
        percentileThresholds
    };
};

export const selectStoreSizes = createSelector(
    selectPortfolioStores,
    (stores) => {
        const storeSizes = stores
            .map(store => store.sizeInSquareFeet)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(storeSizes);
    }
);

export const selectNumberOfEmployees = createSelector(
    selectPortfolioStores,
    (stores) => {
        const numbersOfEmployees = stores
            .map(store => store.numberOfEmployees)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(numbersOfEmployees);
    }
);

export const selectRevenue = createSelector(
    selectPortfolioStores,
    (stores) => {
        const revenueValues = stores
            .map(store => store.revenue)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(revenueValues);
    }
);

export const selectGrossProfitMargin = createSelector(
    selectPortfolioStores,
    (stores) => {
        const grossProfitMarginValues = stores
            .map(store => store.grossProfitMargin)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(grossProfitMarginValues);
    }
);

export const selectRevenuePerSquareFoot = createSelector(
    selectPortfolioStores,
    (stores) => {
        const revenuePerSquareFootValues = stores
            .map(store => (store.revenue / store.sizeInSquareFeet))
            .sort((a, b) => a - b);
        return generateSliderThresholds(revenuePerSquareFootValues);
    }
);

export const selectCatchmentSize = createSelector(
    selectPortfolioStores,
    (stores) => {
        const catchmentSizes = stores
            .map(store => store.catchmentSize)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(catchmentSizes);
    }
);

export const selectNumberOfCompetitors = createSelector(
    selectPortfolioStores,
    (stores) => {
        const competitorCounts = stores
            .map(store => store.numberOfCompetitors)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(competitorCounts);
    }
);

export const selectChangeInNumberOfStores = createSelector(
    selectPortfolioStores,
    (stores) => {
        const changeInNumberOfStoresValues = stores
            .map(store => store.changeInNumberOfStores)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(changeInNumberOfStoresValues);
    }
);

export const selectFootfallLevel = createSelector(
    selectPortfolioStores,
    (stores) => {
        const footfallLevels = stores
            .map(store => store.footfallLevel)
            .sort((sizeA, sizeB) => sizeA - sizeB);
        return generateSliderThresholds(footfallLevels);
    }
);

export const selectIsStoresFilterModified = createSelector(
    selectStoresFilter,
    (storesFilter) => {
        return storesFilter.clientRegion !== initialState.storesFilter.clientRegion
            || storesFilter.region !== initialState.storesFilter.region
            || storesFilter.storeCategory !== initialState.storesFilter.storeCategory
            || storesFilter.pitchType !== initialState.storesFilter.pitchType
            || storesFilter.groupType !== initialState.storesFilter.groupType
            || storesFilter.formatType !== initialState.storesFilter.formatType
            || storesFilter.segmentType !== initialState.storesFilter.segmentType
            || storesFilter.openingDateFrom !== initialState.storesFilter.openingDateFrom
            || storesFilter.openingDateTo !== initialState.storesFilter.openingDateTo
            || storesFilter.storeSize[0] !== initialState.storesFilter.storeSize[0]
            || storesFilter.storeSize[1] !== initialState.storesFilter.storeSize[1]
            || storesFilter.numberOfEmployees[0] !== initialState.storesFilter.numberOfEmployees[0]
            || storesFilter.numberOfEmployees[1] !== initialState.storesFilter.numberOfEmployees[1]
            || storesFilter.revenue[0] !== initialState.storesFilter.revenue[0]
            || storesFilter.revenue[1] !== initialState.storesFilter.revenue[1]
            || storesFilter.grossProfitMargin[0] !== initialState.storesFilter.grossProfitMargin[0]
            || storesFilter.grossProfitMargin[1] !== initialState.storesFilter.grossProfitMargin[1]
            || storesFilter.revenuePerSquareFoot[0] !== initialState.storesFilter.revenuePerSquareFoot[0]
            || storesFilter.revenuePerSquareFoot[1] !== initialState.storesFilter.revenuePerSquareFoot[1]
            || storesFilter.catchmentSize[0] !== initialState.storesFilter.catchmentSize[0]
            || storesFilter.catchmentSize[1] !== initialState.storesFilter.catchmentSize[1]
            || storesFilter.numberOfCompetitors[0] !== initialState.storesFilter.numberOfCompetitors[0]
            || storesFilter.numberOfCompetitors[1] !== initialState.storesFilter.numberOfCompetitors[1]
            || storesFilter.changeInNumberOfStores[0] !== initialState.storesFilter.changeInNumberOfStores[0]
            || storesFilter.changeInNumberOfStores[1] !== initialState.storesFilter.changeInNumberOfStores[1]
            || storesFilter.footfallLevel[0] !== initialState.storesFilter.footfallLevel[0]
            || storesFilter.footfallLevel[1] !== initialState.storesFilter.footfallLevel[1];
    }
);

export const selectIsCustomSelectionStoresFilterModified = createSelector(
    selectCustomSelectionStoresFilter,
    (customSelectionStoresFilter) => {
        return customSelectionStoresFilter.clientRegion !== initialState.customSelectionStoresFilter.clientRegion
            || customSelectionStoresFilter.region !== initialState.customSelectionStoresFilter.region
            || customSelectionStoresFilter.storeCategory !== initialState.customSelectionStoresFilter.storeCategory
            || customSelectionStoresFilter.pitchType !== initialState.customSelectionStoresFilter.pitchType
            || customSelectionStoresFilter.groupType !== initialState.customSelectionStoresFilter.groupType
            || customSelectionStoresFilter.formatType !== initialState.customSelectionStoresFilter.formatType
            || customSelectionStoresFilter.segmentType !== initialState.customSelectionStoresFilter.segmentType
            || customSelectionStoresFilter.openingDateFrom !== initialState.customSelectionStoresFilter.openingDateFrom
            || customSelectionStoresFilter.openingDateTo !== initialState.customSelectionStoresFilter.openingDateTo
            || customSelectionStoresFilter.similarity[0] !== initialState.customSelectionStoresFilter.similarity[0]
            || customSelectionStoresFilter.similarity[1] !== initialState.customSelectionStoresFilter.similarity[1]
            || customSelectionStoresFilter.storeSize[0] !== initialState.customSelectionStoresFilter.storeSize[0]
            || customSelectionStoresFilter.storeSize[1] !== initialState.customSelectionStoresFilter.storeSize[1]
            || customSelectionStoresFilter.numberOfEmployees[0] !== initialState.customSelectionStoresFilter.numberOfEmployees[0]
            || customSelectionStoresFilter.numberOfEmployees[1] !== initialState.customSelectionStoresFilter.numberOfEmployees[1]
            || customSelectionStoresFilter.revenue[0] !== initialState.customSelectionStoresFilter.revenue[0]
            || customSelectionStoresFilter.revenue[1] !== initialState.customSelectionStoresFilter.revenue[1]
            || customSelectionStoresFilter.grossProfitMargin[0] !== initialState.customSelectionStoresFilter.grossProfitMargin[0]
            || customSelectionStoresFilter.grossProfitMargin[1] !== initialState.customSelectionStoresFilter.grossProfitMargin[1]
            || customSelectionStoresFilter.revenuePerSquareFoot[0] !== initialState.customSelectionStoresFilter.revenuePerSquareFoot[0]
            || customSelectionStoresFilter.revenuePerSquareFoot[1] !== initialState.customSelectionStoresFilter.revenuePerSquareFoot[1]
            || customSelectionStoresFilter.catchmentSize[0] !== initialState.customSelectionStoresFilter.catchmentSize[0]
            || customSelectionStoresFilter.catchmentSize[1] !== initialState.customSelectionStoresFilter.catchmentSize[1]
            || customSelectionStoresFilter.numberOfCompetitors[0] !== initialState.customSelectionStoresFilter.numberOfCompetitors[0]
            || customSelectionStoresFilter.numberOfCompetitors[1] !== initialState.customSelectionStoresFilter.numberOfCompetitors[1]
            || customSelectionStoresFilter.changeInNumberOfStores[0] !== initialState.customSelectionStoresFilter.changeInNumberOfStores[0]
            || customSelectionStoresFilter.changeInNumberOfStores[1] !== initialState.customSelectionStoresFilter.changeInNumberOfStores[1]
            || customSelectionStoresFilter.footfallLevel[0] !== initialState.customSelectionStoresFilter.footfallLevel[0]
            || customSelectionStoresFilter.footfallLevel[1] !== initialState.customSelectionStoresFilter.footfallLevel[1];
    }
);

export const selectStores = createSelector(
    selectPortfolioStores,
    selectStoresSearch,
    selectStoresFilter,
    selectStoresSort,
    selectIsStoresFilterModified,
    (stores, search, filter, sort, isFilterModified) => {
        const name = search.name.toLowerCase();
        const filteredStores = !isFilterModified
            ? stores.filter(store => !name || store.name.toLowerCase().includes(name))
            : stores.filter(store =>
                (!name || store.name.toLowerCase().includes(name))
                && (!filter.clientRegion || store.clientRegion === filter.clientRegion)
                && (!filter.region || store.region === filter.region)
                && (!filter.storeCategory || store.kpmgStoreCategory === filter.storeCategory)
                && (!filter.pitchType || store.retailCentreClassificationName === filter.pitchType)
                && (!filter.groupType || store.group === filter.groupType)
                && (!filter.formatType || store.format === filter.formatType)
                && (!filter.segmentType || store.segment === filter.segmentType)
                && (!filter.openingDateFrom || store.openingDate >= filter.openingDateFrom)
                && (!filter.openingDateTo || store.openingDate <= filter.openingDateTo)
                && store.sizeInSquareFeet >= filter.storeSize[0]
                && store.sizeInSquareFeet <= filter.storeSize[1]
                && store.numberOfEmployees >= filter.numberOfEmployees[0]
                && store.numberOfEmployees <= filter.numberOfEmployees[1]
                && store.revenue >= filter.revenue[0]
                && store.revenue <= filter.revenue[1]
                && store.grossProfitMargin >= filter.grossProfitMargin[0]
                && store.grossProfitMargin <= filter.grossProfitMargin[1]
                && store.revenuePerSquareFoot >= filter.revenuePerSquareFoot[0]
                && store.revenuePerSquareFoot <= filter.revenuePerSquareFoot[1]
                && store.catchmentSize >= filter.catchmentSize[0]
                && store.catchmentSize <= filter.catchmentSize[1]
                && store.numberOfCompetitors >= filter.numberOfCompetitors[0]
                && store.numberOfCompetitors <= filter.numberOfCompetitors[1]
                && store.changeInNumberOfStores >= filter.changeInNumberOfStores[0]
                && store.changeInNumberOfStores <= filter.changeInNumberOfStores[1]
                && store.footfallLevel >= filter.footfallLevel[0]
                && store.footfallLevel <= filter.footfallLevel[1]
            );

        return [...filteredStores].sort((storeA, storeB) => {
            switch (sort.field) {
                case StoreSortField.StoreName:
                    return stringSortExpression(storeA.name, storeB.name, sort.direction);
                case StoreSortField.OpeningDate:
                    return dateSortExpression(storeA.openingDate, storeB.openingDate, sort.direction);
                case StoreSortField.StoreSize:
                    return numberSortExpression(storeA.sizeInSquareFeet, storeB.sizeInSquareFeet, sort.direction);
                case StoreSortField.Employees:
                    return numberSortExpression(storeA.numberOfEmployees, storeB.numberOfEmployees, sort.direction);
                case StoreSortField.Revenue:
                    return numberSortExpression(storeA.revenue, storeB.revenue, sort.direction);
                case StoreSortField.GrossProfitMargin:
                    return numberSortExpression(storeA.grossProfitMargin, storeB.grossProfitMargin, sort.direction);
                case StoreSortField.RevenuePerSquareFoot:
                    return numberSortExpression(storeA.revenuePerSquareFoot, storeB.revenuePerSquareFoot, sort.direction);
                case StoreSortField.CatchmentSize:
                    return numberSortExpression(storeA.catchmentSize, storeB.catchmentSize, sort.direction);
                case StoreSortField.NumberOfCompetitors:
                    return numberSortExpression(storeA.numberOfCompetitors, storeB.numberOfCompetitors, sort.direction);
                case StoreSortField.ChangeInNumberOfStores:
                    return numberSortExpression(storeA.changeInNumberOfStores, storeB.changeInNumberOfStores, sort.direction);
                case StoreSortField.FootfallLevel:
                    return numberSortExpression(storeA.footfallLevel, storeB.footfallLevel, sort.direction);
                case StoreSortField.RadarColour:
                default:
                    return numberSortExpression(storeA.getTotalScore(), storeB.getTotalScore(), sort.direction);
            }
        });
    }
);

export const selectStoresWithSimilarityScores = createSelector(
    selectPortfolioStores,
    selectCandidateStore,
    (stores, candidateStore) => {
        const similarStores = candidateStore?.similarStores ?? [];
        return stores.map(store => {
            const similarityScore = similarStores.find(similarStore => similarStore.id === store.id)?.similarityScore ?? null;
            return new StoreWithSimilarityScore(store, similarityScore);
        });
    }
);

export const selectComparators = createSelector(
    selectStoresWithSimilarityScores,
    selectCandidateStore,
    selectGroupTypes,
    selectStoreCategories,
    selectPitchTypes,
    selectSegmentTypes,
    selectFormatTypes,
    selectClientRegions,
    (stores, candidateStore, groupTypes, storeCategories, pitchTypes, segmentTypes, formatTypes, clientRegions) => {
        let comparators: Comparator[] = [];

        const customComparator = new Comparator(ComparatorType.Custom, "Custom selection", []);
        comparators.push(customComparator);

        const similarStoreIds = (candidateStore?.similarStores ?? [])
            .filter(similarStore => similarStore.includedInDashComparator)
            .map(similarStore => similarStore.id);
        if (similarStoreIds.length > 0) {
            const similarStores = stores.filter(store => similarStoreIds.includes(store.id));
            const dashSmartComparator = new Comparator(ComparatorType.SmartStoreSelection, "Dash smart store selection", similarStores);
            comparators.push(dashSmartComparator);
        }

        const allOtherStores = stores.filter(s => s.id !== candidateStore?.id);
        if (allOtherStores.length > 0) {
            const allStoresComparator = new Comparator(ComparatorType.AllStores, "All stores", allOtherStores);
            comparators.push(allStoresComparator);
        }

        const storesInClientRegion = allOtherStores.filter(s => s.clientRegion === candidateStore?.clientRegion);
        if (storesInClientRegion.length > 0 && clientRegions.length > 1 && candidateStore?.clientRegion.toLocaleLowerCase() !== "blank") {
            const allStoresInClientRegionComparator = new Comparator(ComparatorType.AllStoresInClientRegion, `All stores in ${candidateStore?.clientRegion}`, storesInClientRegion);
            comparators.push(allStoresInClientRegionComparator);

            if (storesInClientRegion.length > 5) {
                const sortedStores = storesInClientRegion.sort((storeA, storeB) => numberSortExpression(storeA.getTotalScore(), storeB.getTotalScore(), SortDirection.DESC));
                const topFiveStores = sortedStores.slice(0, 5);
                const topFiveStoresInClientRegionComparator = new Comparator(ComparatorType.TopFiveStoresInClientRegion, `Top 5 stores in ${candidateStore?.clientRegion}`, topFiveStores);
                comparators.push(topFiveStoresInClientRegionComparator);

            }
        }

        const storesInRegion = allOtherStores.filter(s => s.region === candidateStore?.region);
        if (storesInRegion.length > 0) {
            const allStoresInRegionComparator = new Comparator(ComparatorType.AllStoresInRegion, `All stores in ${candidateStore?.region}`, storesInRegion);
            comparators.push(allStoresInRegionComparator);

            const sortedStores = storesInRegion.sort((storeA, storeB) => numberSortExpression(storeA.getTotalScore(), storeB.getTotalScore(), SortDirection.DESC));
            const topFiveStores = sortedStores.slice(0, 5);
            const topFiveStoresInRegionComparator = new Comparator(ComparatorType.TopFiveStoresInRegion, `Top 5 stores in ${candidateStore?.region}`, topFiveStores);
            comparators.push(topFiveStoresInRegionComparator);
        }

        if (allOtherStores.length > 0 && groupTypes.length > 1) {
            const storesInGroup = allOtherStores.filter(s => s.group === candidateStore?.group);
            if (storesInGroup.length > 0) {
                const allStoresInGroupComparator = new Comparator(ComparatorType.AllStoresInGroup, `All ${candidateStore?.group} stores`, storesInGroup);
                comparators.push(allStoresInGroupComparator);
            }
        }

        if (allOtherStores.length > 0 && storeCategories.length > 1) {
            const storesInCategory = allOtherStores.filter(s => s.kpmgStoreCategory === candidateStore?.kpmgStoreCategory);
            if (storesInCategory.length > 0) {
                const allStoresInCategoryComparator = new Comparator(ComparatorType.AllStoresInCategory, `All ${candidateStore?.kpmgStoreCategory.toLocaleLowerCase()}`, storesInCategory);
                comparators.push(allStoresInCategoryComparator);
            }
        }

        if (allOtherStores.length > 0 && pitchTypes.length > 1) {
            const storesInPitchType = allOtherStores.filter(s => s.retailCentreClassificationName === candidateStore?.retailCentreClassificationName);
            if (storesInPitchType.length > 0) {
                const allStoresInPitchTypeComparator = new Comparator(ComparatorType.AllStoresInPitchType, `All stores in ${candidateStore?.retailCentreClassificationName.toLocaleLowerCase()}`, storesInPitchType);
                comparators.push(allStoresInPitchTypeComparator);
            }
        }

        if (allOtherStores.length > 0 && segmentTypes.length > 1) {
            if (candidateStore?.segment.toLocaleLowerCase() !== "blank") {
                const storesInSegmentType = allOtherStores.filter(s => s.segment === candidateStore?.segment);
                if (storesInSegmentType.length > 0) {
                    const allStoresInSegmentTypeComparator = new Comparator(ComparatorType.AllStoresInSegment, `All ${candidateStore?.segment.toLocaleLowerCase()} stores`, storesInSegmentType);
                    comparators.push(allStoresInSegmentTypeComparator);
                }
            }
        }

        if (allOtherStores.length > 0 && formatTypes.length > 1) {
            if (candidateStore?.format.toLocaleLowerCase() !== "blank") {
                const storesInFormatType = allOtherStores.filter(s => s.format === candidateStore?.format);
                if (storesInFormatType.length > 0) {
                    const allStoresInFormatTypeComparator = new Comparator(ComparatorType.AllStoresInFormat, `All ${candidateStore?.format.toLocaleLowerCase()} stores`, storesInFormatType);
                    comparators.push(allStoresInFormatTypeComparator);
                }
            }
        }

        return comparators;
    }
);

export const selectCustomSelectionStores = createSelector(
    selectStoresWithSimilarityScores,
    selectCandidateStore,
    selectCustomSelectionStoresSearch,
    selectCustomSelectionStoresFilter,
    selectCustomSelectionStoresSort,
    selectIsCustomSelectionStoresFilterModified,
    (stores, candidateStore, search, filter, sort, isFilterModified) => {
        const name = search.name.toLowerCase();
        const filteredStores = !isFilterModified
            ? stores.filter(store =>
                store.id !== candidateStore?.id
                && (!name || store.name.toLowerCase().includes(name)))
            : stores.filter(store =>
                store.id !== candidateStore?.id
                && (!name || store.name.toLowerCase().includes(name))
                && (!filter.clientRegion || store.clientRegion === filter.clientRegion)
                && (!filter.region || store.region === filter.region)
                && (!filter.storeCategory || store.kpmgStoreCategory === filter.storeCategory)
                && (!filter.pitchType || store.retailCentreClassificationName === filter.pitchType)
                && (!filter.groupType || store.group === filter.groupType)
                && (!filter.formatType || store.format === filter.formatType)
                && (!filter.segmentType || store.segment === filter.segmentType)
                && (!filter.openingDateFrom || store.openingDate >= filter.openingDateFrom)
                && (!filter.openingDateTo || store.openingDate <= filter.openingDateTo)
                && (store.similarityScore ?? 0) >= filter.similarity[0]
                && (store.similarityScore ?? 0) <= filter.similarity[1]
                && store.sizeInSquareFeet >= filter.storeSize[0]
                && store.sizeInSquareFeet <= filter.storeSize[1]
                && store.numberOfEmployees >= filter.numberOfEmployees[0]
                && store.numberOfEmployees <= filter.numberOfEmployees[1]
                && store.revenue >= filter.revenue[0]
                && store.revenue <= filter.revenue[1]
                && store.grossProfitMargin >= filter.grossProfitMargin[0]
                && store.grossProfitMargin <= filter.grossProfitMargin[1]
                && store.revenuePerSquareFoot >= filter.revenuePerSquareFoot[0]
                && store.revenuePerSquareFoot <= filter.revenuePerSquareFoot[1]
                && store.catchmentSize >= filter.catchmentSize[0]
                && store.catchmentSize <= filter.catchmentSize[1]
                && store.numberOfCompetitors >= filter.numberOfCompetitors[0]
                && store.numberOfCompetitors <= filter.numberOfCompetitors[1]
                && store.changeInNumberOfStores >= filter.changeInNumberOfStores[0]
                && store.changeInNumberOfStores <= filter.changeInNumberOfStores[1]
                && store.footfallLevel >= filter.footfallLevel[0]
                && store.footfallLevel <= filter.footfallLevel[1]
            );

        return filteredStores.sort((storeA, storeB) => {
            switch (sort.field) {
                case CustomSelectionStoreSortField.StoreName:
                    return stringSortExpression(storeA.name, storeB.name, sort.direction);
                case CustomSelectionStoreSortField.OpeningDate:
                    return dateSortExpression(storeA.openingDate, storeB.openingDate, sort.direction);
                case CustomSelectionStoreSortField.StoreSize:
                    return numberSortExpression(storeA.sizeInSquareFeet, storeB.sizeInSquareFeet, sort.direction);
                case CustomSelectionStoreSortField.Employees:
                    return numberSortExpression(storeA.numberOfEmployees, storeB.numberOfEmployees, sort.direction);
                case CustomSelectionStoreSortField.Revenue:
                    return numberSortExpression(storeA.revenue, storeB.revenue, sort.direction);
                case CustomSelectionStoreSortField.GrossProfitMargin:
                    return numberSortExpression(storeA.grossProfitMargin, storeB.grossProfitMargin, sort.direction);
                case CustomSelectionStoreSortField.RevenuePerSquareFoot:
                    return numberSortExpression(storeA.revenuePerSquareFoot, storeB.revenuePerSquareFoot, sort.direction);
                case CustomSelectionStoreSortField.CatchmentSize:
                    return numberSortExpression(storeA.catchmentSize, storeB.catchmentSize, sort.direction);
                case CustomSelectionStoreSortField.NumberOfCompetitors:
                    return numberSortExpression(storeA.numberOfCompetitors, storeB.numberOfCompetitors, sort.direction);
                case CustomSelectionStoreSortField.ChangeInNumberOfStores:
                    return numberSortExpression(storeA.changeInNumberOfStores, storeB.changeInNumberOfStores, sort.direction);
                case CustomSelectionStoreSortField.FootfallLevel:
                    return numberSortExpression(storeA.footfallLevel, storeB.footfallLevel, sort.direction);
                case CustomSelectionStoreSortField.RadarColour:
                    return numberSortExpression(storeA.getTotalScore(), storeB.getTotalScore(), sort.direction);
                case CustomSelectionStoreSortField.Similarity:
                default:
                    return numberSortExpression(storeA.similarityScore ?? 0, storeB.similarityScore ?? 0, sort.direction);
            }
        });
    }
);

export default filtersSlice;
