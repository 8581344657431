import { ResultSet } from "@cubejs-client/core";
import { DateTime } from "luxon";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export const loadReferenceDate = (): AppThunk<Promise<DateTime>> => async (dispatch) => {
    try {
        const query = {
            measures: ["D_Date.MaxDate"],
            segments: [
                "ProductSalesEstimate.ValidSales"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const dateString = resultSet.rawData()[0]["D_Date.MaxDate"];
        return DateTime.fromISO(dateString, { zone: "utc" });
    } catch (error) {
        dispatch(logError("Error loading ReferenceDate.", error));
        throw error;
    }
};
