import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { RootState } from "store";
import { logError } from "modules/helpers/logger/loggerSlice";
import { selectProducts, selectReferenceDate, selectProductCategories, selectSelectedRange, selectSelectedStore } from "modules/customer/tools/product/productSlice";
import { ProductCategoryMultiSelect } from "modules/customer/tools/product/productCategory";
import { loadRetailCentreClassification } from "./retailCentreClassification";
import { loadStoreMonthlySales, StoreMonthlySales } from "./storeMonthlySales";
import { DataWrapper } from "domain/dataWrapper";
import { numberSortExpression, SortDirection } from "utils/sortUtils";
import { SalesMixTreemapRow } from "pages/customer/tools/product/insight/storeOpportunities/productMix/SalesMixTreemap";
import _ from "lodash";

interface StoreOpportunitiesState {
    isLoading: boolean,
    hasErrors: boolean,
    retailCentreClassification: String,
    storeMonthlySales: StoreMonthlySales[],
    productSalesMixProductCategories: ProductCategoryMultiSelect[]
}

const initialState: StoreOpportunitiesState = {
    isLoading: false,
    hasErrors: false,
    retailCentreClassification: "",
    storeMonthlySales: [],
    productSalesMixProductCategories: []
};

interface LoadStoreOpportunitiesResponse {
    retailCentreClassification: String,
    storeMonthlySales: StoreMonthlySales[],
    productSalesMixProductCategories: ProductCategoryMultiSelect[]
}

const storeOpportunitiesSlice = createSlice({
    name: "customer/tools/product/storeOpportunities",
    initialState,
    reducers: {
        clearRetailCentreClassification: (state) => {
            state.retailCentreClassification = initialState.retailCentreClassification;
        },
        clearStoreMonthlySales: (state) => {
            state.storeMonthlySales = initialState.storeMonthlySales;
        },
        toggleProductSalesMixProductCategoryIsSelected: (state, action: PayloadAction<string>) => {
            const categoryName = action.payload;
            state.productSalesMixProductCategories.find(category => category.name === categoryName)?.toggleIsSelected();
        },
        chooseAllProductSalesMixProductCategories: (state) => {
            state.productSalesMixProductCategories.forEach(category => category.setIsSelected(true));
        },
        deselectAllProductSalesMixProductCategories: (state) => {
            state.productSalesMixProductCategories.forEach(category => category.setIsSelected(false));
        },
        clearProductSalesMixProductCategories: (state) => {
            state.productSalesMixProductCategories = initialState.productSalesMixProductCategories;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadStoreOpportunities.pending, (state: StoreOpportunitiesState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.retailCentreClassification = initialState.retailCentreClassification;
            state.storeMonthlySales = initialState.storeMonthlySales;
            state.productSalesMixProductCategories = initialState.productSalesMixProductCategories;
        });
        builder.addCase(loadStoreOpportunities.rejected, (state: StoreOpportunitiesState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.retailCentreClassification = initialState.retailCentreClassification;
            state.storeMonthlySales = initialState.storeMonthlySales;
            state.productSalesMixProductCategories = initialState.productSalesMixProductCategories;
        });
        builder.addCase(loadStoreOpportunities.fulfilled, (state: StoreOpportunitiesState, action: PayloadAction<LoadStoreOpportunitiesResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.retailCentreClassification = action.payload.retailCentreClassification;
            state.storeMonthlySales = action.payload.storeMonthlySales;
            state.productSalesMixProductCategories = action.payload.productSalesMixProductCategories;
        });
    }
});

export const loadStoreOpportunities = createAppAsyncThunk(
    "customer/tools/product/store/loadStoreOpportunities",
    async (arg, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const referenceDate = selectReferenceDate(state);
            const selectedStore = selectSelectedStore(state);
            const selectedRange = selectSelectedRange(state);
            const productCategories = selectProductCategories(state);
            const productSalesMixProductCategories = productCategories.map(category => new ProductCategoryMultiSelect(
                category,
                true
            ));

            const retailCentreClassificationPromise = thunkAPI.dispatch(loadRetailCentreClassification(selectedStore));
            const monthlySalesPromise = thunkAPI.dispatch(loadStoreMonthlySales(referenceDate, selectedStore?.id, selectedRange));

            const results = await Promise.all([retailCentreClassificationPromise, monthlySalesPromise]);
            const loadStoreOpportunitiesResponse: LoadStoreOpportunitiesResponse = {
                retailCentreClassification: results[0],
                storeMonthlySales: results[1],
                productSalesMixProductCategories
            };
            return loadStoreOpportunitiesResponse;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading Store Opportunities.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const {
    toggleProductSalesMixProductCategoryIsSelected,
    chooseAllProductSalesMixProductCategories,
    deselectAllProductSalesMixProductCategories
} = storeOpportunitiesSlice.actions;

export const clearStoreOpportunities = (): AppThunk => async (dispatch) => {
    dispatch(storeOpportunitiesSlice.actions.clearRetailCentreClassification());
    dispatch(storeOpportunitiesSlice.actions.clearStoreMonthlySales());
    dispatch(storeOpportunitiesSlice.actions.clearProductSalesMixProductCategories());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.hasErrors;
};

export const selectRetailCentreClassification = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.retailCentreClassification;
};

export const selectStoreMonthlySales = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.storeMonthlySales;
};

export const selectProductSalesMixProductCategories = (state: RootState) => {
    return state.customer.tools.product.storeOpportunities.productSalesMixProductCategories;
};

export const selectProductSalesMix = createSelector(
    (state: RootState) => selectProducts(state),
    (state: RootState) => selectProductSalesMixProductCategories(state),
    (products, productCategories) => {
        const selectedCategories = productCategories.filter(category => category.isSelected()).map(category => category.name);
        return {
            ...products,
            data: products.data.filter(product => selectedCategories.includes(product.productCategory))
        };
    }
);

export const selectEstimatedSalesMix = createSelector(
    (state: RootState) => selectProducts(state),
    (state: RootState) => selectProductCategories(state),
    (products, productCategories) => {
        const { data: productsData, isLoading, hasErrors } = products;
        const estimatedSalesMix: DataWrapper<SalesMixTreemapRow[]> = {
            isLoading,
            hasErrors,
            data: []
        };
        if (isLoading || hasErrors || productsData.length === 0) {
            return estimatedSalesMix;
        }

        const totalEstimatedSales = productsData.reduce((total, current) => total + current.sales.estimatedSales, 0);

        const salesByProductCategory = _(productsData)
            .groupBy(product => product.productCategory)
            .map((group, productCategory) => ({
                id: productCategories.find(item => item.name === productCategory)?.id ?? 0,
                name: productCategory,
                salesValue: group.reduce((accumulator, product) => accumulator + product.sales.estimatedSales, 0)
            }))
            .value();

        const productCategoryData: SalesMixTreemapRow[] = [...salesByProductCategory]
            .sort((a, b) => numberSortExpression(a.salesValue, b.salesValue, SortDirection.DESC))
            .map((productCategory, index) => ({
                id: `category-${productCategory.id}`,
                name: productCategory.name,
                colourIndex: index % 8,
                type: "Category",
                parent: undefined,
                salesValue: productCategory.salesValue,
                percentageOfTotalSales: 100 * (productCategory.salesValue / totalEstimatedSales)
            }));

        const productData: SalesMixTreemapRow[] = [...productsData]
            .map(product => {
                const productCategory = productCategoryData.find(category => category.name === product.productCategory);
                return {
                    id: `sub-category-${product.id}`,
                    name: product.name,
                    colourIndex: productCategory?.colourIndex ?? 0,
                    type: "Subcategory",
                    parent: String(productCategory?.id),
                    salesValue: product.sales.estimatedSales,
                    percentageOfTotalSales: 100 * (product.sales.estimatedSales / (productCategory?.salesValue ?? 0))
                };
            });

        estimatedSalesMix.data = productCategoryData.concat(productData);
        return estimatedSalesMix;
    }
);

export const selectOptimisedSalesMix = createSelector(
    (state: RootState) => selectProducts(state),
    (state: RootState) => selectProductCategories(state),
    (products, productCategories) => {
        const { data: productsData, isLoading, hasErrors } = products;
        const optimisedSalesMix: DataWrapper<SalesMixTreemapRow[]> = {
            isLoading,
            hasErrors,
            data: []
        };
        if (isLoading || hasErrors || productsData.length === 0) {
            return optimisedSalesMix;
        }

        const totalOptimisedSales = productsData.reduce((total, current) => total + current.sales.optimisedSales, 0);

        const salesByProductCategory = _(productsData)
            .groupBy(product => product.productCategory)
            .map((group, productCategory) => ({
                id: productCategories.find(item => item.name === productCategory)?.id ?? 0,
                name: productCategory,
                salesValue: group.reduce((accumulator, product) => accumulator + product.sales.optimisedSales, 0)
            }))
            .value();

        const productCategoryData: SalesMixTreemapRow[] = [...salesByProductCategory]
            .sort((a, b) => numberSortExpression(a.salesValue, b.salesValue, SortDirection.DESC))
            .map((productCategory, index) => ({
                id: `category-${productCategory.id}`,
                name: productCategory.name,
                colourIndex: index % 8,
                type: "Category",
                parent: undefined,
                salesValue: productCategory.salesValue,
                percentageOfTotalSales: 100 * (productCategory.salesValue / totalOptimisedSales)
            }));

        const productData: SalesMixTreemapRow[] = [...productsData]
            .map(product => {
                const productCategory = productCategoryData.find(category => category.name === product.productCategory);
                return {
                    id: `sub-category-${product.id}`,
                    name: product.name,
                    colourIndex: productCategory?.colourIndex ?? 0,
                    type: "Subcategory",
                    parent: String(productCategory?.id),
                    salesValue: product.sales.optimisedSales,
                    percentageOfTotalSales: 100 * (product.sales.optimisedSales / (productCategory?.salesValue ?? 0))
                };
            });

        optimisedSalesMix.data = productCategoryData.concat(productData);
        return optimisedSalesMix;
    }
);


export default storeOpportunitiesSlice;
