import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import dateUtils from "utils/dateUtils";

import numberFormatter from "utils/numberFormatter";
import { SortDirection, stringSortExpression } from "utils/sortUtils";

export enum GeoIndicator {
    GB = "GB",
    NI = "NI"
}

export class Store {
    public readonly id: string;
    public readonly name: string;
    public readonly region: string;
    public readonly geoIndicator: GeoIndicator;
    public readonly outputAreaCode: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly openingDate: Date;
    public readonly sizeInSquareFeet: number;
    public readonly numberOfEmployees: number;
    public readonly retailCentreID: number;
    public readonly kpmgStoreCategory: string;
    public readonly storeCategoryID: number;
    public readonly segment: string;
    public readonly format: string;
    public readonly group: string;
    public readonly clientRegion: string;
    public readonly revenue: number;
    public readonly grossProfitMargin: number;
    public readonly similarStores: SimilarStore[];
    public retailCentreClassificationName: string;
    public changeInNumberOfStores: number;
    public catchmentSize: number;
    public numberOfCompetitors: number;
    public footfallLevel: number;
    public areaHealthScore: number;
    public catchmentScore: number;
    public competitionScore: number;
    public footfallScore: number;
    public profitScore: number;
    public revenueScore: number;
    public revenuePerSquareFoot: number;

    constructor(
        id: string,
        name: string,
        region: string,
        geoIndicator: GeoIndicator,
        outputAreaCode: string,
        latitude: number,
        longitude: number,
        openingDate: Date,
        sizeInSquareFeet: number,
        numberOfEmployees: number,
        retailCentreID: number,
        kpmgStoreCategory: string,
        storeCategoryID: number,
        segment: string,
        format: string,
        group: string,
        clientRegion: string,
        revenue: number,
        grossProfitMargin: number,
        similarStores: SimilarStore[],
        retailCentreClassificationName: string,
        changeInNumberOfStores: number,
        catchmentSize: number,
        numberOfCompetitors: number,
        footfallLevel: number,
        areaHealthScore: number,
        catchmentScore: number,
        competitionScore: number,
        footfallScore: number,
        profitScore: number,
        revenueScore: number,
        revenuePerSquareFoot: number,
    ) {
        this.id = id;
        this.name = name;
        this.region = region;
        this.geoIndicator = geoIndicator;
        this.outputAreaCode = outputAreaCode;
        this.latitude = latitude;
        this.longitude = longitude;
        this.openingDate = openingDate;
        this.sizeInSquareFeet = sizeInSquareFeet;
        this.numberOfEmployees = numberOfEmployees;
        this.retailCentreID = retailCentreID;
        this.kpmgStoreCategory = kpmgStoreCategory;
        this.storeCategoryID = storeCategoryID;
        this.segment = segment;
        this.format = format;
        this.group = group;
        this.clientRegion = clientRegion;
        this.revenue = revenue;
        this.grossProfitMargin = grossProfitMargin;
        this.similarStores = similarStores;
        this.retailCentreClassificationName = retailCentreClassificationName;
        this.changeInNumberOfStores = changeInNumberOfStores;
        this.catchmentSize = catchmentSize;
        this.numberOfCompetitors = numberOfCompetitors;
        this.footfallLevel = footfallLevel;
        this.areaHealthScore = areaHealthScore;
        this.catchmentScore = catchmentScore;
        this.competitionScore = competitionScore;
        this.footfallScore = footfallScore;
        this.profitScore = profitScore;
        this.revenueScore = revenueScore;
        this.revenuePerSquareFoot = revenuePerSquareFoot;
    }

    getTotalScore(): number {
        return this.areaHealthScore + this.catchmentScore + this.competitionScore + this.footfallScore + this.profitScore + this.revenueScore;
    }
}

export class StoreWithSimilarityScore extends Store {
    public readonly similarityScore: number | null;

    constructor(
        store: Store,
        similarityScore: number | null
    ) {
        super(
            store.id,
            store.name,
            store.region,
            store.geoIndicator,
            store.outputAreaCode,
            store.latitude,
            store.longitude,
            store.openingDate,
            store.sizeInSquareFeet,
            store.numberOfEmployees,
            store.retailCentreID,
            store.kpmgStoreCategory,
            store.storeCategoryID,
            store.segment,
            store.format,
            store.group,
            store.clientRegion,
            store.revenue,
            store.grossProfitMargin,
            store.similarStores,
            store.retailCentreClassificationName,
            store.changeInNumberOfStores,
            store.catchmentSize,
            store.numberOfCompetitors,
            store.footfallLevel,
            store.areaHealthScore,
            store.catchmentScore,
            store.competitionScore,
            store.footfallScore,
            store.profitScore,
            store.revenueScore,
            store.revenuePerSquareFoot,
        );
        this.similarityScore = similarityScore;
    }

    displaySimilarityScore(): string {
        return this.similarityScore ? numberFormatter.toPercentage(this.similarityScore, true) : "< 70%";
    }
}

class SimilarStore {
    public readonly id: string;
    public readonly similarityScore: number;
    public readonly includedInDashComparator: boolean;

    constructor(
        rawSimilarStore: RawSimilarStore,
    ) {
        this.id = String(rawSimilarStore.SimilarStoreNaturalID);
        this.similarityScore = rawSimilarStore.SimilarityScore;
        this.includedInDashComparator = Boolean(Number(rawSimilarStore.Flag));
    }
}

interface RawSimilarStore {
    SimilarStoreNaturalID: number,
    SimilarityScore: number,
    Flag: number
}

const calculateScore = (sortedStores: Store[], currentStore: Store, chapterMetric: keyof Store): number => {
    const index = sortedStores.findIndex(store => store[chapterMetric] === currentStore[chapterMetric]);
    return 5 * (sortedStores.length - (index + 1)) / (sortedStores.length - 1);
};

const arrayJoinOnKey = (arrays: any[][], joinKey: string, alreadySorted?: boolean) => {
    if (arrays.length === 0) {
        return [];
    } else if (arrays.length === 1) {
        return arrays[0];
    }

    const sortedArrays = alreadySorted ? arrays : arrays.map(array =>
        [...array].sort((a, b) => stringSortExpression(a[joinKey] ?? '', b[joinKey] ?? '', SortDirection.ASC))
    );
    const arrayIndexes = arrays.map(array => 0);

    return sortedArrays[0].map(baseRow => {
        let joinedRow = baseRow;
        for (let i = 1; i < sortedArrays.length; i++) {
            let currentArray = sortedArrays[i];
            let joiningRow = currentArray[arrayIndexes[i]];

            if (baseRow[joinKey] !== (joiningRow ? joiningRow[joinKey] : undefined)) {
                arrayIndexes[i] = currentArray.findIndex(item => item[joinKey] === baseRow[joinKey]);
                joiningRow = currentArray[arrayIndexes[i]];
            }
            arrayIndexes[i]++;
            joinedRow = Object.assign({}, joinedRow, joiningRow);
        }
        return joinedRow;
    });
};

export const loadStores = (): AppThunk<Promise<Store[]>> => async (dispatch) => {
    try {
        const storesQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName",
                "D_Store.k_Region",
                "D_Store.GeoInd",
                "D_Store.OutputAreaID",
                "D_Store.Lat",
                "D_Store.Long",
                "D_Store.OpeningDate",
                "D_Store.Sqft",
                "D_Store.EmployeeCount",
                "D_Store.RetailCentreID",
                "D_Store.KPMGStoreCategory",
                "D_Store.StoreCategory_ID",
                "D_Store.Segment",
                "D_Store.Format",
                "D_Store.Group",
                "D_Store.ClientRegion",
                "Store_RAGMetrics.Sales",
                "Store_RAGMetrics.Profit",
                "StoreCluster.SimilarStores"
            ],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            limit: 50000
        };

        const retailCentresQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "Client_RC_RagMetrics.AreaHealth",
                "Client_RC_RagMetrics.Catchment",
                "Client_RC_RagMetrics.Competition",
                "Client_RC_RagMetrics.Footfall",
                "Client_RC_RagMetrics.RetailCentreClassification"
            ],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            limit: 50000
        };

        const storesPromise = dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
        const retailCentresPromise = dispatch(cubeLoad(retailCentresQuery)) as unknown as ResultSet;

        const results = await Promise.all([storesPromise, retailCentresPromise]);

        const storesRawData = results[0].rawData();
        const retailCentresRawData = results[1].rawData();
        const combinedRawData = arrayJoinOnKey([storesRawData, retailCentresRawData], "D_Store.StoreNaturalID", true);

        const stores = combinedRawData.map(row => new Store(
            row["D_Store.StoreNaturalID"],
            row["D_Store.StoreName"],
            row["D_Store.k_Region"],
            row["D_Store.GeoInd"] === 'GB' ? GeoIndicator.GB : GeoIndicator.NI,
            row["D_Store.OutputAreaID"],
            row["D_Store.Lat"],
            row["D_Store.Long"],
            dateUtils.dateUTC(row["D_Store.OpeningDate"]),
            row["D_Store.Sqft"],
            Number(row["D_Store.EmployeeCount"]),
            row["D_Store.RetailCentreID"],
            row["D_Store.KPMGStoreCategory"],
            Number(row["D_Store.StoreCategory_ID"]),
            row["D_Store.Segment"] ?? "Blank",
            row["D_Store.Format"] ?? "Blank",
            row["D_Store.Group"] ?? "Blank",
            row["D_Store.ClientRegion"] ?? "Blank",
            Number(row["Store_RAGMetrics.Sales"]),
            Number(row["Store_RAGMetrics.Profit"]),
            JSON.parse(row["StoreCluster.SimilarStores"] ?? '[]')
                .map((rawSimilarStore: RawSimilarStore) => new SimilarStore(rawSimilarStore)),
            row["Client_RC_RagMetrics.RetailCentreClassification"] ?? "",
            Number(row["Client_RC_RagMetrics.AreaHealth"]) ?? 0,
            Number(row["Client_RC_RagMetrics.Catchment"]) ?? 0,
            Number(row["Client_RC_RagMetrics.Competition"]) ?? 0,
            Number(row["Client_RC_RagMetrics.Footfall"]) ?? 0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ));

        //ToDo: Make this async as for large numbers of stores it causes the app to freeze
        const storesSortedByAreaHealth = [...stores].sort((a, b) => b.changeInNumberOfStores - a.changeInNumberOfStores);
        const storesSortedByCatchment = [...stores].sort((a, b) => b.catchmentSize - a.catchmentSize);
        const storesSortedByCompetition = [...stores].sort((a, b) => b.numberOfCompetitors - a.numberOfCompetitors);
        const storesSortedByFootfall = [...stores].sort((a, b) => b.footfallLevel - a.footfallLevel);
        const storesSortedByProfit = [...stores].sort((a, b) => b.grossProfitMargin - a.grossProfitMargin);
        const storesSortedByRevenue = [...stores].sort((a, b) => b.revenue - a.revenue);

        stores.forEach(store => {
            store.areaHealthScore = calculateScore(storesSortedByAreaHealth, store, 'changeInNumberOfStores');
            store.catchmentScore = calculateScore(storesSortedByCatchment, store, 'catchmentSize');
            store.competitionScore = calculateScore(storesSortedByCompetition, store, 'numberOfCompetitors');
            store.footfallScore = calculateScore(storesSortedByFootfall, store, 'footfallLevel');
            store.profitScore = calculateScore(storesSortedByProfit, store, 'grossProfitMargin');
            store.revenueScore = calculateScore(storesSortedByRevenue, store, 'revenue');
            store.revenuePerSquareFoot = (store.revenue / store.sizeInSquareFeet);
        });
        return stores;
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};
