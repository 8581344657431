import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import dateUtils from "utils/dateUtils";

import arrayUtils from "utils/newArrayUtils";

export class Store {
    public readonly id: string;
    public readonly outputAreaCode: string;
    public readonly retailCentreID: number;
    public readonly storeCategoryID: number;
    public readonly name: string;
    public readonly group: string;
    public readonly kpmgStoreCategory: string;
    public readonly clientRegion: string;
    public readonly region: string;
    public readonly sizeInSquareFeet: number;
    public readonly format: string;
    public readonly segment: string;
    public readonly openingDate: Date;
    public readonly numberOfEmployees: number;
    public readonly weeklyRevenue: number;
    public readonly yearlyRevenue: number;
    public readonly revenuePerSquareFoot: number;
    public readonly revenuePerHead: number;
    public readonly grossProfit: number;
    public readonly grossProfitMargin: number;
    public retailCentreClassificationName: string;
    public changeInNumberOfStores: number;
    public catchmentSize: number;
    public numberOfCompetitors: number;
    public footfallLevel: number;

    constructor(
        id: string,
        outputAreaCode: string,
        retailCentreID: number,
        storeCategoryID: number,
        name: string,
        group: string,
        kpmgStoreCategory: string,
        clientRegion: string,
        region: string,
        sizeInSquareFeet: number,
        format: string,
        segment: string,
        openingDate: Date,
        numberOfEmployees: number,
        weeklyRevenue: number,
        yearlyRevenue: number,
        revenuePerSquareFoot: number,
        grossProfit: number,
        grossProfitMargin: number,
        retailCentreClassificationName: string,
        changeInNumberOfStores: number,
        catchmentSize: number,
        numberOfCompetitors: number,
        footfallLevel: number,
    ) {
        this.id = id;
        this.outputAreaCode = outputAreaCode;
        this.retailCentreID = retailCentreID;
        this.storeCategoryID = storeCategoryID;
        this.name = name;
        this.group = group;
        this.kpmgStoreCategory = kpmgStoreCategory;
        this.clientRegion = clientRegion;
        this.region = region;
        this.sizeInSquareFeet = sizeInSquareFeet;
        this.format = format;
        this.segment = segment;
        this.openingDate = openingDate;
        this.numberOfEmployees = numberOfEmployees;
        this.weeklyRevenue = weeklyRevenue;
        this.yearlyRevenue = yearlyRevenue;
        this.revenuePerSquareFoot = revenuePerSquareFoot;
        this.revenuePerHead = numberOfEmployees === 0 ? 0 : yearlyRevenue / numberOfEmployees;
        this.grossProfit = grossProfit;
        this.grossProfitMargin = grossProfitMargin;
        this.retailCentreClassificationName = retailCentreClassificationName;
        this.changeInNumberOfStores = changeInNumberOfStores;
        this.catchmentSize = catchmentSize;
        this.numberOfCompetitors = numberOfCompetitors;
        this.footfallLevel = footfallLevel;
    }
}

export const loadStores = (): AppThunk<Promise<Store[]>> => async (dispatch) => {
    try {
        const storesQuery = {
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName",
                "D_Store.k_Region",
                "D_Store.OutputAreaID",
                "D_Store.Lat",
                "D_Store.Long",
                "D_Store.OpeningDate",
                "D_Store.Sqft",
                "D_Store.EmployeeCount",
                "D_Store.RetailCentreID",
                "D_Store.KPMGStoreCategory",
                "D_Store.StoreCategory_ID",
                "D_Store.Segment",
                "D_Store.Format",
                "D_Store.Group",
                "D_Store.ClientRegion",
                "Store_RAGMetrics.Sales",
                "Store_RAGMetrics.Profit",
            ],
            limit: 50000
        };

        const retailCentresQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "Client_RC_RagMetrics.AreaHealth",
                "Client_RC_RagMetrics.Catchment",
                "Client_RC_RagMetrics.Competition",
                "Client_RC_RagMetrics.Footfall",
                "Client_RC_RagMetrics.RetailCentreClassification"
            ],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            limit: 50000
        };

        const storesPromise = dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
        const retailCentresPromise = dispatch(cubeLoad(retailCentresQuery)) as unknown as ResultSet;

        const newResults = await Promise.all([storesPromise, retailCentresPromise]);

        const storesRawData = newResults[0].rawData();
        const retailCentresRawData = newResults[1].rawData();
        const combinedRawData = arrayUtils.arrayJoinOnKey([storesRawData, retailCentresRawData], "D_Store.StoreNaturalID", true);

        const stores = combinedRawData.map(row => {
            const yearlyRevenue = row["Store_RAGMetrics.Sales"] ?? 0;
            const grossProfitMargin = row["Store_RAGMetrics.Profit"] ?? 0;
            return new Store(
                row["D_Store.StoreNaturalID"],
                row["D_Store.OutputAreaID"],
                Number(row["D_Store.RetailCentreID"]),
                Number(row["D_Store.StoreCategory_ID"]),
                row["D_Store.StoreName"],
                row["D_Store.Group"],
                row["D_Store.KPMGStoreCategory"],
                row["D_Store.ClientRegion"],
                row["D_Store.k_Region"],
                row["D_Store.Sqft"],
                row["D_Store.Format"],
                row["D_Store.Segment"],
                dateUtils.dateUTC(row["D_Store.OpeningDate"]),
                row["D_Store.EmployeeCount"],
                yearlyRevenue / 52,
                yearlyRevenue,
                yearlyRevenue / row["D_Store.Sqft"],
                grossProfitMargin * yearlyRevenue,
                grossProfitMargin,
                row["Client_RC_RagMetrics.RetailCentreClassification"] ?? "",
                Number(row["Client_RC_RagMetrics.AreaHealth"]) ?? 0,
                Number(row["Client_RC_RagMetrics.Catchment"]) ?? 0,
                Number(row["Client_RC_RagMetrics.Competition"]) ?? 0,
                Number(row["Client_RC_RagMetrics.Footfall"]) ?? 0
            );
        });

        return stores;
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};
