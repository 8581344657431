import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";

import { Store } from "modules/customer/insights/portfolioNew/store";

import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import mathUtils from "utils/mathUtils";
import { numberSortExpression, SortDirection } from "utils/sortUtils";

export class PotentiallyCannibalisedStore {
    public readonly name: string;
    public readonly storeCategoryId: number;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly retailCentreId: number;
    public readonly distanceToProposedStore: number;

    constructor(
        name: string,
        storeCategoryId: number,
        latitude: number,
        longitude: number,
        retailCentreId: number,
        distanceToProposedStore: number
    ) {
        this.name = name;
        this.storeCategoryId = storeCategoryId;
        this.latitude = latitude;
        this.longitude = longitude;
        this.retailCentreId = retailCentreId;
        this.distanceToProposedStore = distanceToProposedStore;
    }
}

export const loadPotentiallyCannibalisedStores = (selectedStore?: Store, stores?: Store[]): AppThunk<Promise<PotentiallyCannibalisedStore[]>> => async (dispatch) => {
    try {
        if (!selectedStore || !stores) {
            return [] as PotentiallyCannibalisedStore[];
        }
        //Filter to nearest 500 stores
        const filteredStores = stores
            .map(store => {
                const distanceToProposedStore = mathUtils.haversineDistance(
                    selectedStore.latitude,
                    selectedStore.longitude,
                    store.latitude,
                    store.longitude
                );
                return {
                    ...store,
                    distanceToProposedStore
                };
            })
            .sort((a, b) => numberSortExpression(a.distanceToProposedStore, b.distanceToProposedStore, SortDirection.ASC))
            .slice(0, 500);

        const filteredStoreIds = filteredStores.map(store => store.id);

        const query = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "ClientStores_CatchmentAreasPopulation.MaxDistance"
            ],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [...filteredStoreIds, selectedStore.id]
            }]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        const selectedStoreData = rawData.find(row => row["D_Store.StoreNaturalID"] === selectedStore.id);
        const selectedStoreMaxReach = {
            latitude: selectedStore.latitude,
            longitude: selectedStore.longitude,
            maximumReach: Number(selectedStoreData["ClientStores_CatchmentAreasPopulation.MaxDistance"] ?? 0)
        };

        const potentiallyCannibalisedStores = [];
        const existingStoresCatchmentData = rawData.filter(row => row["D_Store.StoreNaturalID"] !== selectedStore.id);

        for (const store of filteredStores) {
            const catchmentData = existingStoresCatchmentData.find(row => store.id === row["D_Store.StoreNaturalID"]);
            if (catchmentData) {
                const maximumReach = Number(catchmentData["ClientStores_CatchmentAreasPopulation.MaxDistance"] ?? 0);
                const distanceToProposedStore = mathUtils.haversineDistance(
                    selectedStore.latitude,
                    selectedStore.longitude,
                    store.latitude,
                    store.longitude
                );

                if (distanceToProposedStore <= (selectedStoreMaxReach.maximumReach + maximumReach)) {
                    potentiallyCannibalisedStores.push(new PotentiallyCannibalisedStore(
                        store.name,
                        store.storeCategoryID,
                        store.latitude,
                        store.longitude,
                        store.retailCentreID,
                        distanceToProposedStore
                    ));
                }
            }
        }

        return potentiallyCannibalisedStores as PotentiallyCannibalisedStore[];
    } catch (error) {
        dispatch(logError("Error loading CannibalisedStores.", error));
        throw error;
    }
};
