import { immerable } from "immer";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { ResultSet } from "@cubejs-client/core";
import { logError } from "modules/helpers/logger/loggerSlice";
import { Store } from "modules/customer/insights/portfolioNew/store";

export class SpendCategory {
    [immerable] = true;

    public readonly name: string;
    private _isSelected: boolean;

    constructor(
        name: string,
        isSelected: boolean
    ) {
        this.name = name;
        this._isSelected = isSelected;
    }

    isSelected() {
        return this._isSelected;
    }

    setIsSelected(isSelected: boolean) {
        this._isSelected = isSelected;
    }

    toggleIsSelected() {
        this._isSelected = !this._isSelected;
    }
}

export const loadSelectedStoreSpendCategories = (selectedStore?: Store): AppThunk<Promise<number[]>> => async (dispatch) => {
    try {
        if (!selectedStore) {
            return [];
        }
        const query = {
            measures: ["F_Sales.SumLineValue"],
            dimensions: ["D_ProductCategory.SpendCategory_ID"],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: [selectedStore.id]
            }, {
                member: "F_Sales.SumLineValue",
                operator: "set"
            }],
            segments: [
                "D_ProductCategory.ValidCategories"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        return resultSet.rawData().map(row => {
            return row["D_ProductCategory.SpendCategory_ID"];
        });
    } catch (error) {
        dispatch(logError("Error loading Selected Store Spend Categories.", error));
        throw error;
    }
};
