import React from "react";
import Highcharts from "highcharts";

import { useTheme } from "@material-ui/core";

import Treemap from "components/visuals/Treemap";
import useColourPalette from "components/visuals/useColourPalette";

import numberFormatter from "utils/numberFormatter";
import stringUtils from "utils/stringUtils";

interface SalesMixTreemapProps {
    isLoading: boolean,
    hasErrors: boolean,
    salesTypeName: string,
    data: SalesMixTreemapRow[]
}

export interface SalesMixTreemapRow {
    id: string,
    name: string,
    type: "Category" | "Subcategory" | "Product",
    colourIndex: number,
    parent: string | undefined,
    salesValue: number,
    percentageOfTotalSales: number
}

const SalesMixTreemap: React.FC<SalesMixTreemapProps> = (props) => {
    const { isLoading, hasErrors, salesTypeName, data } = props;
    const theme = useTheme();
    const colourPalette = useColourPalette();

    const chartData = data.map(row => ({
        id: row.id,
        name: row.name,
        parent: row.parent,
        value: row.percentageOfTotalSales,
        color: colourPalette.categorical[row.colourIndex],
        custom: {
            salesValue: row.salesValue,
            type: row.type
        }
    }));

    const options = React.useMemo((): Highcharts.Options => ({
        chart: {
            height: 250,
            inverted: true,
            // scrollablePlotArea: {
            //     minWidth: numberOfParents * 70
            // }
        },
        tooltip: {
            useHTML: true,
            headerFormat: "",
            footerFormat: "",
            formatter: function (this: Highcharts.TooltipFormatterContextObject) {
                const customOptions = this.point?.options?.custom;
                const parent = this.point.options.parent;
                const percentageLabel = parent ? `Percentage of ${parent}<br>sales` : "Percentage of total<br>sales";
                return `<table><tr><td colspan="2" style="">${customOptions?.type}:</td></tr>
                ${stringUtils.tooltipHTML([" ", `${salesTypeName} sales`, percentageLabel], {
                    header: `${this.key}`,
                    values: [" ",
                        numberFormatter.toGBP(customOptions?.salesValue, 1),
                        numberFormatter.toPercentage(this.point?.value ?? 0, true, 1)
                    ],
                    valueFormatting: `color:${this.color}`
                }
                )}</table>`;
            }
        },
        series: [{
            type: "treemap",
            data: chartData,
            layoutAlgorithm: "stripes",
            layoutStartingDirection: "horizontal",
            turboThreshold: 0,
            levels: [{
                dataLabels: {
                    style: {
                        textOverflow: "clip",
                        fontFamily: theme.typography.fontFamily
                    },
                    crop: true,
                    overflow: "justify",
                    formatter: function () {
                        const pointPercentage = this.point.value ?? 0;
                        // ToDo: Add drilldown series
                        // const pointSales = this.point.options?.custom?.salesValue;
                        // const parent = this.point.options.parent;
                        // const type = this.point.options.custom?.type;

                        // const topLevelPoints = this.series.data.filter(item => item?.options?.custom?.type === "Supergroup");
                        // const topLevelValues: number[] = topLevelPoints.map(item => item.options?.custom?.numberOfVisitors) ?? [0];
                        // const topLevelTotal = topLevelValues.reduce((accumulator, value) => accumulator + value, 0);

                        // const parentVisitors = (type === "Supergroup") ? topLevelTotal : this.series.data.find(item => item.name === parent)?.options.custom?.numberOfVisitors ?? 1e9;
                        // const percentageOfLevelTotal = 100 * pointSales / parentVisitors;

                        const percentageOfLevelTotal = pointPercentage;
                        let formattedPointName = this.point.name;
                        if (formattedPointName.indexOf(" ") > -1) {
                            formattedPointName = this.point.name.replaceAll(" ", "<br>");
                        }

                        if (percentageOfLevelTotal >= 5) {
                            return `<table>${stringUtils.tooltipHTML([`${numberFormatter.toPercentage(pointPercentage, true, 1)}`], {
                                header: formattedPointName,
                                values: ["&nbsp;"],
                                headerFormatting: `color: ${theme.palette.common.black}; fontWeight: 400; fontSize: 14px`,
                                categoryFormatting: `color: ${theme.palette.common.black}; fontWeight: 600; fontSize: 18px`,
                                overrideDefaultHeaderFormatting: true,
                                overrideDefaultCategoryFormatting: true
                            })}</table>`;
                        } else {
                            return "";
                        }

                    }
                }
            }]
        }]
    }), [chartData, salesTypeName, theme]);

    return (
        <Treemap loading={isLoading} error={hasErrors} options={options} />
    );
};

export default SalesMixTreemap;
