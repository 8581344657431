import { SortDirection, stringSortExpression } from "./sortUtils";

const arrayJoinOnKey = (arrays: any[][], joinKey: string, alreadySorted?: boolean) => {
    if (arrays.length === 0) {
        return [];
    } else if (arrays.length === 1) {
        return arrays[0];
    }

    const sortedArrays = alreadySorted ? arrays : arrays.map(array =>
        [...array].sort((a, b) => stringSortExpression(a[joinKey] ?? '', b[joinKey] ?? '', SortDirection.ASC))
    );
    const arrayIndexes = arrays.map(array => 0);

    return sortedArrays[0].map(baseRow => {
        let joinedRow = baseRow;
        for (let i = 1; i < sortedArrays.length; i++) {
            let currentArray = sortedArrays[i];
            let joiningRow = currentArray[arrayIndexes[i]];

            if (baseRow[joinKey] !== (joiningRow ? joiningRow[joinKey] : undefined)) {
                arrayIndexes[i] = currentArray.findIndex(item => item[joinKey] === baseRow[joinKey]);
                joiningRow = currentArray[arrayIndexes[i]];
            }
            arrayIndexes[i]++;
            joinedRow = Object.assign({}, joinedRow, joiningRow);
        }
        return joinedRow;
    });
};


const arrayUtils = {
    arrayJoinOnKey
};

export default arrayUtils;
