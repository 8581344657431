import { Query, ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { KPMGSpendCategory } from "./kpmgSpendCategory";
import { Store } from "./store";

export class CatchmentSpend {
    public readonly storeId: string;
    public readonly catchmentSpend: number;

    constructor(
        storeId: string,
        catchmentSpend: number
    ) {
        this.storeId = storeId;
        this.catchmentSpend = catchmentSpend;
    }
}

export const loadCatchmentSpend = (stores: Store[], clientId: string, spendCategories: KPMGSpendCategory[]): AppThunk<Promise<CatchmentSpend[]>> => async (dispatch) => {
    try {
        // ToDo remove stores param from this
        const spendCategoryIds = spendCategories.map(item => String(item.id));
        const query: Query = {
            measures: [
                "LocationBenchmarkSpendMetrics.SumWeightedSpend"
            ],
            dimensions: [
                "LocationBenchmarkSpendMetrics.RetailCentreID",
                "LocationBenchmarkSpendMetrics.StoreCategory_ID",
            ],
            filters: [{
                member: "LocationBenchmarkSpendMetrics.Client_ID",
                operator: "equals",
                values: [clientId]
            }, {
                member: "LocationBenchmarkSpendMetrics.SpendCategory_ID",
                operator: "equals",
                values: spendCategoryIds
            }],
            segments: ["LocationBenchmarkSpendMetrics.Baseline"],
            limit: 50000
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        return stores.map(store => {
            const catchmentSpendRow = rawData.find(row => row["LocationBenchmarkSpendMetrics.RetailCentreID"] === store.retailCentreID
                && row["LocationBenchmarkSpendMetrics.StoreCategory_ID"] === store.storeCategoryID);
            return new CatchmentSpend(
                store.id,
                catchmentSpendRow ? catchmentSpendRow["LocationBenchmarkSpendMetrics.SumWeightedSpend"] : null
            );
        });
    } catch (error) {
        dispatch(logError("Error loading CatchmentSpend.", error));
        throw error;
    }
};
