import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { PinnedLocation } from "modules/customer/tools/location/pinnedLocation";
import { RetailCentre } from "modules/customer/tools/location/retailCentre";
import { Store } from "modules/customer/tools/location/store";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import mathUtils from "utils/mathUtils";
import { numberSortExpression, SortDirection } from "utils/sortUtils";

export class PotentiallyCannibalisedStore {
    public readonly name: string;
    public readonly storeCategoryId: number;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly retailCentre: RetailCentre;
    public readonly distanceToProposedStore: number;

    constructor(
        name: string,
        storeCategoryId: number,
        latitude: number,
        longitude: number,
        retailCentre: RetailCentre,
        distanceToProposedStore: number
    ) {
        this.name = name;
        this.storeCategoryId = storeCategoryId;
        this.latitude = latitude;
        this.longitude = longitude;
        this.retailCentre = retailCentre;
        this.distanceToProposedStore = distanceToProposedStore;
    }
}

export const loadPotentiallyCannibalisedStores = (pinnedLocation?: PinnedLocation, targetStoreCategoryId?: number, stores?: Store[]): AppThunk<Promise<PotentiallyCannibalisedStore[]>> => async (dispatch) => {
    try {
        if (!pinnedLocation || !targetStoreCategoryId || !stores) {
            return [] as PotentiallyCannibalisedStore[];
        }
        //Filter to nearest 500 stores
        const filteredStores = stores
            .map(store => {
                const distanceToProposedStore = mathUtils.haversineDistance(
                    pinnedLocation.latitude,
                    pinnedLocation.longitude,
                    store.latitude,
                    store.longitude
                );
                return {
                    ...store,
                    distanceToProposedStore
                };
            })
            .sort((a, b) => numberSortExpression(a.distanceToProposedStore, b.distanceToProposedStore, SortDirection.ASC))
            .slice(0, 500);

        const filteredStoreIds = filteredStores.map(store => store.id);

        const proposedStoreQuery = {
            dimensions: [
                "CatchmentAreasPopulation.MaxDistance"
            ],
            filters: [{
                member: "CatchmentAreasPopulation.IsScenario",
                operator: "equals",
                values: ["1"]
            }, {
                member: "CatchmentAreasPopulation.StoreCategory_ID",
                operator: "equals",
                values: [String(targetStoreCategoryId)]
            }, {
                member: "CatchmentAreasPopulation.RetailCentreID",
                operator: "equals",
                values: [String(pinnedLocation.retailCentre.id)]
            }]
        };
        const proposedStorePromise = dispatch(cubeLoad(proposedStoreQuery));

        const existingStoresQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "ClientStores_CatchmentAreasPopulation.MaxDistance"
            ],
            filters: [{
                member: "D_Store.StoreNaturalID",
                operator: "equals",
                values: filteredStoreIds
            }]
        };
        const existingStoresPromise = dispatch(cubeLoad(existingStoresQuery));
        const results = await Promise.all([proposedStorePromise, existingStoresPromise]);
        const proposedStoreResultSet = results[0] as unknown as ResultSet;
        const existingStorsResultSet = results[1] as unknown as ResultSet;

        const proposedStoreMaxReach = proposedStoreResultSet.rawData()[0]["CatchmentAreasPopulation.MaxDistance"];
        const proposedStore = {
            latitude: pinnedLocation.latitude,
            longitude: pinnedLocation.longitude,
            maximumReach: proposedStoreMaxReach
        };

        const potentiallyCannibalisedStores = [];

        for (const store of filteredStores) {
            const catchmentData = existingStorsResultSet.rawData().find(row => store.id === row["D_Store.StoreNaturalID"]);

            if (catchmentData) {
                const maximumReach = Number(catchmentData["CatchmentAreasPopulation.MaxDistance"] ?? 0);
                const distanceToProposedStore = mathUtils.haversineDistance(
                    pinnedLocation.latitude,
                    pinnedLocation.longitude,
                    store.latitude,
                    store.longitude
                );

                if (distanceToProposedStore <= (proposedStore.maximumReach + maximumReach)) {
                    potentiallyCannibalisedStores.push(new PotentiallyCannibalisedStore(
                        store.name,
                        store.categoryId,
                        store.latitude,
                        store.longitude,
                        store.retailCentre,
                        distanceToProposedStore
                    ));
                }
            }
        }

        return potentiallyCannibalisedStores as PotentiallyCannibalisedStore[];
    } catch (error) {
        dispatch(logError("Error loading CannibalisedStores.", error));
        throw error;
    }
};
