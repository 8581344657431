import React from "react";

import { Card, CardContent, Chip, Typography, useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import useColourPalette from "components/visuals/useColourPalette";

const StyledCard = withStyles(theme => ({
    root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer,
        margin: theme.spacing(2),
        bottom: theme.spacing(2),
        width: theme.spacing(22),
        // @ts-ignore
        backgroundColor: theme.palette.background.insight
    }
}))(Card);

const StyledCardContent = withStyles(theme => ({
    root: {
        "&:last-child": {
            padding: theme.spacing(2)
        }
    }
}))(CardContent);

const StyledChip = withStyles(theme => ({
    root: {
        backgroundColor: (props: any) => props["data-background-color"],
        width: theme.spacing(3),
        height: theme.spacing(1.5)
    }
}))(Chip);

const Legend: React.FC = () => {
    const colourPalette = useColourPalette();
    const theme = useTheme();
    return (
        <StyledCard elevation={0}>
            <StyledCardContent>
                <Typography variant="body1" component="div" gutterBottom style={{ color: theme.palette.text.disabled }}>
                    Likelihood of visit thresholds
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.locationOverview[0]} />
                    &nbsp;&nbsp;&gt; 80%
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.locationOverview[1]} />
                    &nbsp;&nbsp;80% - 60%
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.locationOverview[2]} />
                    &nbsp;&nbsp;60% - 40%
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.locationOverview[3]} />
                    &nbsp;&nbsp;40% - 20%
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                    <StyledChip size="small" data-background-color={colourPalette.locationOverview[4]} />
                    &nbsp;&nbsp;&lt; 20%
                </Typography>
            </StyledCardContent>
        </StyledCard>
    );
};

export default Legend;
